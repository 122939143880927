import rankNodes from "./rankNodes";
import moment from "moment";
import store from "../store/store";

const categorysOfChangeIds: string[] = [];
let previousCompletedOrDeferedDates: string[] = [];

// Define the Node interface for better type checking
interface Node {
    _id: string;
    isCapturePark?: boolean;
    recurring?: {
        frequency?: string;
        days?: number[];
        startTime?: string;
        endTime?: string;
    };
    children?: Node[];
    isDefered?: boolean;
    deferByTask?: boolean;
    deferByTaskDate?: string;
    deferedInheritedFrom?: string;
    isCompleted?: boolean;
    isDeleted?: boolean;
    completedOn?: string[];
    taskExploitationDurationAsArray?: any[];
    estimatedTaskDuration?: number;
    // previousEstTaskDuration?: any[];
    totalEstimatedTaskDuration?: number;
    totalTaskExploitationDuration?: number;
    taskPlanningDuration?: any[];
    totalTaskPlanningDuration?: any[];
    weight?: number; // add properties as needed
    deadline?: Date; // add properties as needed
    [key: string]: any; // for additional dynamic properties
}

function initialSetup(root: Node): void {
    previousCompletedOrDeferedDates = [];
    root.children = root.children?.filter(n => !n.isCapturePark) || [];

    root.children.forEach(child => {
        child.children?.forEach(ch => {
            categorysOfChangeIds.push(ch._id);
        });
    });
    markChildNodeCompletedIfParentIsCompleted(root);
    calculateCompletedPortion(root);
    visit(root);
    visit1(root);
}

export default initialSetup;

function visit(d: any): void {
    
    

    // deadline 
    markUnDefered(d);
    makrAllChildrenDiffered(d);
    recurringCompletion(d);


    d.children?.forEach((child:any) => visit(child));

    // leaf to root 
    markParentDefered(d);
    parentNodeComplete(d);
    parentNodeDelete(d);
    totalEstDuration(d);
    totalExploitation(d);
    totalPlanningDuration(d);
}

function visit1(d: Node): void {
    // deadline 
    relativeImpactValueNodeAsScale10to0(d);
    d.children?.forEach(child => visit1(child));
}

// mark undefered if time has been expired
function markUnDefered(d: Node): void {
    if (d.deferByTask && d.deferByTaskDate) {
        const isThere = previousCompletedOrDeferedDates.find(ms => {
            return new Date(ms) > new Date(d.deferByTaskDate || "");
        });

        if (!d.deferedInheritedFrom) {
            d.isDefered = !isThere;
        }
    } else {
        if (!d.deferedInheritedFrom) {
            if (d.isDefered && d.deferedTill) {
                const diffMinutes = moment(d.deferedTill).diff(moment(), 'minutes');
                d.isDefered = diffMinutes >= 0;
            } else {
                d.isDefered = false;
            }
        }
    }
}

// mark all children defered if parent has been defered 
function makrAllChildrenDiffered(d: Node): void {
    if (d.isDefered) {
        function inner(el: Node): void {
            el.isDefered = true;
            el.deferedInheritedFrom = d._id;

            el.children?.forEach(td => inner(td));
        }
        inner(d);
    }
}

// mark parent defered if all children have been defered 
function markParentDefered(d: any): void {
    if (d.isDefered) {
        return;
    }

    const len = d.children?.filter((e:any) => e.isCompleted !== true && e.isDeleted !== true) || [];
    const defered = d.children?.filter((e:any) => e.isDefered === true) || [];
    
    const deferedInheritedFrom = d.children?.find((e:any) => {
        if (e.isDefered && e.deferedTill) {
            const diffMinutes = moment(e.deferedTill).diff(moment(), 'minutes');
            if (diffMinutes > 0) {
                return e._id;
            }
        }
        return e.deferedInheritedFrom || undefined;
    });

    if (len.length !== 0 && len.length === defered.length) {
        d.isDefered = true;
        d.deferedInheritedFrom = deferedInheritedFrom;
    }
}

export function markChildNodeCompletedIfParentIsCompleted(root:any){
    
    visitOuter(root);
    function visitOuter(n:any){
        if(n.isCompleted){
            visit(n);
            function visit(node:any){

                if(n._id !== node._id && !node.isCompleted){
                    node.isCompleted = true;
                    node.isTempCompleted = true;
                }
    
                if(node.children){
                    node.children.forEach((c:any) => visit(c))
                }
            }
        }

        if(n.children){
            n.children.forEach((child:any) => visitOuter(child))
        }
    }
}

// mark recurring node as completed if done for today and today is not in schedule  
export function recurringCompletion(d: Node): void {
    
    if (d?.recurring?.frequency && d.isRecurringTask) {
        const isTodayIncluded = d.recurring.days?.indexOf(new Date().getDay()) !== -1;

        
        // d.children?.length === 0
        if (isTodayIncluded) {
            const arr = d?.completedOn;
            const lastCompletedOn = arr ? arr[arr.length - 1] : undefined;
            d.deadline = moment().endOf("day").toDate();

            if (lastCompletedOn && moment(lastCompletedOn).isAfter(moment().startOf("day"))) {
                d.isCompleted = true;
                d.isRecurringCompleted = true;
            }
        } 
        else {
            d.isCompleted = true;
            d.isRecurringCompleted = true;
            d.ignoreMe = true;
        }
    }
}

// mark node completed if children are completed
export function parentNodeComplete(d: Node): void {
    if (d.isCompleted) {
        return;
    }

    const state = store.getState();

    const isCOI = categorysOfChangeIds.includes(d._id);

    const completedNodes = d.children?.filter(node => (node.isCompleted || node.isDeleted)) || [];
    if (!isCOI && d.children && (d.children?.length > 0 && (completedNodes.length === d.children.length))) {
        d.isCompleted = true;
        d.isTempCompleted = true;
    }
    // else {
    //     d.isCompleted = false;
    //     d.isTempCompleted = false;    
    // }

    const isCompletedToday = d.children?.find(node => moment(node.updatedAt).startOf("day").isSame(moment().startOf("day")));
    if (!isCompletedToday && d?.recurring?.frequency && !isCOI && d.children && (d.children?.length > 0 && (completedNodes.length === d.children.length))) {
        d.isCompleted = false;
        d.isTempCompleted = false;
    }

    if (!state.globalStates.isCompletedNodesVisible) {
        d.children = d.children?.filter(c => !c.isCompleted) || [];
    }
}

// mark node deleted if children are deleted and delete it from UI 
export function parentNodeDelete(d: Node): void {
    const state = store.getState();

    const deletedNodes = d.children?.filter(node => node.isDeleted) || [];
    if ((d.children && d.children?.length > 0) && (deletedNodes.length === d.children.length)) {
        d.isDeleted = true;
    }

    if (!state.globalStates.isDeletedNodesVisible) {
        d.children = d.children?.filter(c => !c.isDeleted) || [];
    }
}

// calculate total estimated duration 
function totalEstDuration(d: Node): void {
    if (d.children && d.children?.length > 0) {
        d.totalEstimatedTaskDuration = calculateDurationOfChildren(d.children);
    }

    function calculateDurationOfChildren(children: Node[]): number {
        let totalDuration = 0;

        children.forEach(child => {
            if (!child.isCompleted) {
                if (child.children?.length === 0) {
                    totalDuration += child.estimatedTaskDuration || 0;
                } else {
                    totalDuration += Math.max(child.estimatedTaskDuration || 0, child.totalEstimatedTaskDuration || 0);
                }
            }
        });

        return totalDuration === 0 ? 1 : totalDuration;
    }
}

// calculate total exploitation duration 
function totalExploitation(d: Node): void {
    const children = d.children || [];
    d.totalTaskExploitationDuration = calculateDurationOfChildren(children);

    function calculateDurationOfChildren(children: Node[]): number {
        let totalDuration = 0;

        children.forEach((child:any) => {
            if (child.children?.length === 0) {
                totalDuration += child.isCompleted ? child.estimatedTaskDuration || 0 : child.taskExploitationDuration || 0;
            } else {
                totalDuration += (child.taskExploitationDuration || 0) + (child.totalTaskExploitationDuration || 0);
            }
        });

        return totalDuration;
    }
}

// calculate total exploitation duration  
export function totalExploitationDuration(ted: any, isRecurring: boolean): number {
    if (ted === "undefined" || ted === undefined || !ted?.length) {
        return 0;
    }
    const temp = ted.filter((t: any) => t.startTime && t.endTime);

    function getDiff(b: { startTime: string; endTime: string }): number {
        return moment(b.endTime).diff(moment(b.startTime), "seconds");
    }

    let duration = 0;

    if (isRecurring) {   
        duration = temp.reduce((a: number, b: any) => moment().startOf("day").isSame(moment(b.startTime).startOf("day")) ? a + getDiff(b) : a, 0);
    } else {
        duration = temp.reduce((a: number, b: any) => a + getDiff(b), 0); 
    }
    
    return duration / 60;
}

// calculate total planning duration 
function totalPlanningDuration(d: Node): void {
    const children = d.children || [];
    const childrenDuration = calculateDurationOfChildren(children);
    d.totalTaskPlanningDuration = [...childrenDuration, ...(d.taskPlanningDuration || [])];

    function calculateDurationOfChildren(children: Node[]): any[] {
        let totalDuration: any[] = [];

        children.forEach(child => {
            if (child.children?.length === 0) {
                totalDuration = [...totalDuration, ...(child.taskPlanningDuration || [])];
            } else {
                totalDuration = [...totalDuration, ...(child.totalTaskPlanningDuration || [])];
            }
        });

        return totalDuration;
    }
}

// node ranking 
function relativeImpactValueNodeAsScale10to0(d: Node): void {
    // const isRanked = d.children?.find(child => (!child.relativeImpactValueNodeAsScale10to0 && !child.isDeleted));
    const children:any = JSON.parse(JSON.stringify(d.children));
    const completedOnes = children.filter((child:any) => (child.isCompleted || child.isDeleted));
    const notCompletedOnes = children.filter((child:any) => (!child.isCompleted && !child.isDeleted));


    d.children = [...notCompletedOnes, ...completedOnes];

    if (d.children?.length !== 0) {
        rankNodes(d.children, "both");
    }

    const childrenWithTaskOrder = [...d.children].filter(child => (child.taskOrder > 0 && !child.isCompleted && !child.isDeleted)).sort((a, b) => a.taskOrder - b.taskOrder);
    const childrenWithNoTaskOrder = [...d.children].filter(child => ((!child.taskOrder || child.taskOrder <= 0) && !child.isCompleted && !child.isDeleted)).sort((a, b) => b.relativeImpactValueNodeAsScale10to0 - a.relativeImpactValueNodeAsScale10to0);
    const deletedOrCompletedChildren = [...d.children].filter(child => (child.isCompleted || child.isDeleted));


    d.children = [...childrenWithTaskOrder, ...childrenWithNoTaskOrder, ...deletedOrCompletedChildren];
}

// Recursive function to calculate completion percentage and store it in each node
function calculateCompletedPortion(node: Node): void {
    // store all completed or deferred dates 
    if (node.completionDate || node.deferedDate) {
        if (new Date(node.completionDate) > new Date(node.deferedDate)) {
            previousCompletedOrDeferedDates.push(node.completionDate);
        } else {
            previousCompletedOrDeferedDates.push(node.deferedDate);
        }
    }

    if (!node.children || node.children.length === 0) {
        // Leaf node
        recurringCompletion(node);
        node.completedPortion = (node.isCompleted && !node.ignoreMe) ? node.weighting : 0; 
    } 
    else {
        // Parent node
        let totalCompleted = 0;
        const totalChildren = node.children.length;
        const top20 = Math.ceil(0.2 * totalChildren);
        const bottom80 = Math.floor(0.8 * totalChildren);
        const top20PerUnit = 0.8 / top20;
        const bottom80PerUnit = 0.2 / bottom80;

        // sort children based on taskOrder 
        node.children.sort((a, b) => {
            const aTO = !a.taskOrder ? 1000 : a.taskOrder;
            const bTO = !b.taskOrder ? 1000 : b.taskOrder;
            return aTO - bTO;
        });

        node.children.forEach((child, index) => {
            child.weighting = getWeighting(totalChildren, index, top20, top20PerUnit, bottom80PerUnit);
            calculateCompletedPortion(child);

            if (child.children && child.children.length > 0) {
                totalCompleted += child.weighting * child.completedPortion;
            } 
            else {
                totalCompleted += child.completedPortion;
            }
        });

        node.completedPortion = totalCompleted;
    }
}

function getWeighting(totalChildren: number, index: number, top20: number, top20PerUnit: number, bottom80PerUnit: number): number {
    if (totalChildren === 1) {
        return 1;
    }
    if (top20 > index) {
        return top20PerUnit;
    }
    return bottom80PerUnit;
}
