import React from 'react';
import { getTimeScaleValue } from '../utils';

const TimeScale: React.FC = () => {
    return (
        <>
            <div className={`w-[100px] h-[1440px] border-r flex flex-col`}>
                {
                    new Array(48).fill(1).map((_, i) => (
                        <div key={i} className='h-[80px] flex items-start justify-end'>
                            <span className='-translate-y-[50%] pr-1 text-xs text-gray-400'>
                                {getTimeScaleValue(i, true)}
                            </span>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default TimeScale;
