import React from "react";
import Modal from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import {
  extendTaskDuration,
  setTimingAlertModalOpen,
  setStartTime,
} from "../../store/actions";
import { Formik } from "formik";
import Form from "./Form";
// import { toast } from "react-toastify";
// import moment from "moment";
import { RootState } from "../../store/store";

interface IndexProps {}

const Index: React.FC<IndexProps> = () => {
  const dispatch:any = useDispatch();
  const isTimingAlertModalOpen = useSelector((state: RootState) => state.globalStates.isTimingAlertModalOpen); // Replace `any` with your state type
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with your state type
 

  const handleSubmit = (values: any, e: any) => {
   
    const extraDurationInMins = Number(values.minutes);
    const wereYouWorking = values.isWorking === "Yes";
   
    const data = {
      wereYouWorking: wereYouWorking,
      extraEstimatedDuration: extraDurationInMins,
    };

    dispatch(extendTaskDuration(currentTask._id, data));
    dispatch(setTimingAlertModalOpen(false));
    dispatch(setStartTime(new Date()));
    e.resetForm();
  };

  // const handleCompleteBtn = (isWorking: string) => {
  //   dispatch(setTimingAlertModalOpen(false));
  //   dispatch(setIsCurrentTaskRunning(false));
  //   const wereYouWorking = isWorking === "Yes";
  //   const parent = currentTasksParents.find((t:any) => t._id === currentTask.parent);

  //   if (parent?.children?.length === 1) {
  //     dispatch({ type: SET_WERE_YOU_WORKING, payload: wereYouWorking });
  //     dispatch(setTimingAlertModalOpen(false));
  //     dispatch(setParentTaskCompleteAlertModalOpen(true));
  //     return;
  //   }

  //   const isRecurring = !!currentTask.recurring.frequency;
  //   const ed = currentTask.taskExploitationDurationAsArray.reduce((a: number, b: any) => {
  //     if (isRecurring) {
  //       const isToday = moment(b.startTime).isBetween(moment().startOf("day"), moment().endOf("day"));
  //       if (isToday) {
  //         return a + moment(b.endTime).diff(moment(b.startTime), "seconds");
  //       }
  //       return a; 
  //     }
  //     return a + moment(b.endTime).diff(moment(b.startTime), "seconds");
  //   }, 0);

  //   const et = (currentTask.estimatedTaskDuration * 60) - ed;
  //   const popupCameUpTime = moment(currentTask?.startTime).add(et, "seconds").valueOf();

  //   const endTime = wereYouWorking ? new Date().getTime() : popupCameUpTime;

  //   dispatch(completeTask(currentTask._id, endTime.toString(), undefined, wereYouWorking));
    
  //   // dispatch(setStartTime(null));
  // };

  return (
    <Modal
      saveBtnText=""
      onSubmit={handleSubmit}
      isOpen={isTimingAlertModalOpen}
      setIsOpen={(bool) => { dispatch(setTimingAlertModalOpen(bool)); }}
      hideBtns={true}
    >
      <Formik
        initialValues={{ isWorking: "Yes", okrGradePercentage: 0, extraMinutes: 0, minutes: 5 }}
        onSubmit={handleSubmit}
        validate={() => { }}
      >
        {(e) => (<Form e={e} />)}
      </Formik>
    </Modal>
  );
};

export default Index;
