import { 
    SET_ROUTINES,
    ADD_ROUTINE,
    UPDATE_ROUTINE,
    REMOVE_ROUTINE
  } from "../../constants";
  
  interface Routine {
    _id: string;
    [key: string]: any; // Define additional properties as needed
  }
  
  interface Action {
    type: string;
    payload: any; // Define this type based on the payload of each action
  }
  
  const routines = (state: Routine[] = [], action: Action): Routine[] => {
    switch (action.type) {
      case SET_ROUTINES:
        return action.payload;
  
      case ADD_ROUTINE:
        return [...state, action.payload];
  
      case UPDATE_ROUTINE:
        return state.map(routine => routine._id === action.payload._id ? { ...routine, ...action.payload } : routine);
  
      case REMOVE_ROUTINE:
        return state.filter(routine => routine._id !== action.payload.challengeId);
  
      default:
        return state;
    }
  }
  
  export { routines };
  