import React, { useEffect, useRef } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment";
import "react-resizable/css/styles.css";
import TimeScale from "./TimeScale";
import CurrentTimeLine from "./CurrentTimeLine";
import ScheduleDurations from "./ScheduleDurations";
import ExploitationDurations from "./ExploitationDurations";
import PlanningDurations from "./PlanningDurations";
import Lines from "./Lines";
import FocusBlocks from "./FocusBlocks";
import { RootState } from "../../../store/store";

interface WeekViewProps {
  setIsAddEventModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEventToUpdate: React.Dispatch<React.SetStateAction<any>>;
  calenderElVisivility: {
    scheduleDurations: boolean;
    focusBlocks: boolean;
    exploitationDurations: boolean;
    planningDurations: boolean;
  };
  startOfWeek: Moment;
  exploitationDurations: any[];
  planningDurations: any[];
  weekAhead: boolean;
}

const WeekView: React.FC<WeekViewProps> = ({
  setIsAddEventModalOpen,
  setEventToUpdate,
  calenderElVisivility,
  startOfWeek,
  exploitationDurations,
  planningDurations,
  weekAhead,
}) => {
  const temp = startOfWeek.clone().endOf("week");
  const endOfTheWeek = temp.clone().add(1, "day");
  const thisWeekPlanningDurations = planningDurations?.filter((p) =>
    moment(p.startTime).isBetween(startOfWeek, endOfTheWeek)
  );
  const thisWeekExploitationDurations = exploitationDurations?.filter((p) =>
    moment(p.startTime).isBetween(startOfWeek, endOfTheWeek)
  );
  const events:any = useSelector((state: RootState) => state.events);
  const myRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const allLines = myRef.current?.querySelectorAll(".line");
    const minutesElapsed = moment().diff(moment().startOf("day"), "minutes");
    const margin = minutesElapsed > 1140 ? 0 : 300;
    const index = allLines ? allLines[minutesElapsed - margin] || allLines[minutesElapsed] : null;
    index?.scrollIntoView();
  }, []);

  return (
    <div ref={myRef} className="w-full pb-4 h-[calc(100vh-181px)] overflow-auto">
      <TopBar startOfWeek={startOfWeek} />
      <div className="flex py-2">
        <TimeScale />
        <div className={`h-[1440px] flex-1 flex flex-col relative`}>
          <div className="flex absolute w-full h-full">
            {new Array(7).fill(0).map((_, i) => {
              const day = i === 6 ? 0 : i + 1;
              const date = startOfWeek.clone().add(i, "day");
              const currentDay = startOfWeek.clone().add(i, "day");
              const canDisplayFB = currentDay.startOf("day").isSameOrAfter(moment().startOf("day"));

              return (
                <div key={i} className="relative flex-1">
                  <Lines date={new Date(date.toDate())} />
                  {calenderElVisivility.scheduleDurations && (
                    <ScheduleDurations
                      currentDay={date.clone()}
                      todaysEvents={events.filter((e: any) => moment(e.startTime).day() === day)}
                      setEventToUpdate={setEventToUpdate}
                      setIsAddEventModalOpen={setIsAddEventModalOpen}
                    />
                  )}
                  {calenderElVisivility.focusBlocks && canDisplayFB && (
                    <FocusBlocks
                      currentDay={date.clone()}
                      todaysEvents={events.filter((e: any) => moment(e.startTime).day() === day)}
                    />
                  )}
                  {calenderElVisivility.exploitationDurations && (
                    <ExploitationDurations
                      todaysExploitationDurations={thisWeekExploitationDurations.filter((e) =>
                        moment(e.startTime).day() === day
                      )}
                      currentDay={currentDay}
                    />
                  )}
                  {calenderElVisivility.planningDurations && (
                    <PlanningDurations
                      todaysPlanningDurations={thisWeekPlanningDurations.filter((e) =>
                        moment(e.startTime).day() === day
                      )}
                    />
                  )}
                  <CurrentTimeLine currentDay={moment()} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeekView;

interface TopBarProps {
  startOfWeek: Moment;
}

const TopBar: React.FC<TopBarProps> = ({ startOfWeek }) => {
  return (
    <div className="flex sticky top-0 bg-white z-[1] py-3 ml-[100px] justify-around">
      {new Array(7).fill(1).map((_, i) => {
        const day = startOfWeek.clone().add(i, "day");
        return (
          <div className="flex gap-1 justify-center" key={i}>
            <span>{day.format("ddd")}</span>
            <span className="bg-blue-500 w-6 h-6 text-white p-1 rounded-full text-xs flex justify-center items-center">
              {day.format("DD")}
            </span>
          </div>
        );
      })}
    </div>
  );
};
