import moment, { Moment } from "moment";

interface Event {
  startTime: string;
  endTime: string;
}

function getTimeScaleValue(i: number, format: boolean): string | number[] {
  let time: number[];

  time = i % 2 === 0 ? [i / 2, 0] : [Math.floor(i / 2), 30];
  const hour = time[0];

  if (format) {
    return `${hour > 12 ? hour - 12 : hour === 0 ? 12 : hour}:${time[1].toString().padStart(2, '0')} ${i > 23 ? "PM" : "AM"}`;
  }
  return time;
}

function getHeightTopVal(event: Event): { height: number; top: number } {
  const duration = moment.duration(moment(event.endTime).diff(moment(event.startTime)));
  const startOfDay = moment().startOf('day');
  const startTime = moment(event.startTime);
  const today = moment().set({ hours: startTime.hours(), minutes: startTime.minutes() });
  const top = today.diff(startOfDay, 'minutes');

  return { height: duration.asMinutes(), top };
}

export {
  getHeightTopVal,
  getTimeScaleValue
};
