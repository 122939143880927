import React from "react";
import Modal from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import Form from "./Form";
import { setUpcomingTaskModalOpen } from "../../store/actions/globalStates";
import { RootState } from "../../store/store";



const Index: React.FC = () => {
  const dispatch:any = useDispatch();
  const isParentTaskCompleteAlertModalOpen = useSelector((state: RootState) => state.globalStates.isParentTaskCompleteAlertModalOpen); // Adjust type as necessary

  const handleSubmit = () => {
    // e.resetForm();
    // Add your submit logic here
  };

  const onEscape = () => {
    // Handle escape key functionality here
  }

  return (
    <Modal
      saveBtnText=""
      onSubmit={handleSubmit}
      isOpen={isParentTaskCompleteAlertModalOpen}
      setIsOpen={(bool) => { dispatch(setUpcomingTaskModalOpen(bool)); }}
      hideBtns={true}
      onEscape={onEscape}
      showTopArrow={false}
    >
      <Formik
        initialValues={{ isWorking: "Yes", okrGradePercentage: 0, extraMinutes: 0 }}
        onSubmit={handleSubmit}
        validate={() => {}}
      >
        {({ handleSubmit }) => <Form e={{ handleSubmit }} />}
      </Formik>
    </Modal>
  );
}

export default Index;
