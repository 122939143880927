import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setIsFullViewOn, setNavigatePointerNodeId } from "../../../store/actions";
import { RootState } from "../../../store/store";
import { useStorePlanning } from "../../../Hooks";

const SearchBox: React.FC = () => {
    const [search, setSearch] = useState<string>("");
    const [searchResults, setSearchResults] = useState<{ _id: string; objective: string }[]>([]);
    const tree = useSelector((state: RootState) => state.tree);
    const dispatch:any = useDispatch();
    const [isSearching, setIsSearching] = useState(false);
    useStorePlanning();


    const handleClick = (_id: string) => {
      dispatch(setIsFullViewOn(false));
      dispatch(setNavigatePointerNodeId(_id));
    }
  
    useEffect(() => {
      setIsSearching(()=> true);
      const interval = setTimeout(() => {
        const sr: { _id: string; objective: string }[] = [];
        function recur(d: any) {
          if (d.objective.toLowerCase().includes(search.toLowerCase()) && !d.isDeleted && !d.isCompleted) {
            sr.push({ _id: d._id, objective: d.objective });
          }
          if (d.children) {
            d.children.forEach((child: any) => recur(child));
          }
        }
        if (search) {
          recur(tree);
        }
        setIsSearching(()=>false);
        setSearchResults(sr);
      }, 2000);
  
      return () => {
        clearTimeout(interval);
      };
    }, [search, tree]);
  
    useEffect(() => {
      const handleClickOutside = (e: MouseEvent) => {
        if ((e.target as HTMLElement).id !== "search-field") {
          setSearch("");
        }
      };
      document.addEventListener("click", handleClickOutside);
  
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);

   
  
    return (
      <div className='relative'>
        <input
          id='search-field'
          placeholder='Search Objective..'
          onChange={e => setSearch(e.target.value)}
          value={search}
          type='text'
          className='w-[300px] px-3 py-1 my-1 rounded-md text-sm font-medium bg-gray-700 text-gray-400 focus:ring-none active:ring-none'
        />
        {search && (
          <div className='absolute top-[110%] rounded-md left-0 border border-gray-400 bg-white w-[300px] h-[200px]'>
            <div className='w-full h-full overflow-auto flex flex-col p-2 gap-1'>
              {isSearching && <span className="w-full bg-green-200 text-green-600 text-center rounded-md">Please wait, searching...</span>}

              {(searchResults.length === 0 && !isSearching) && <span className="w-full bg-red-200 text-red-600 text-center rounded-md">Sorry, we couldn't find any nodes.</span>}

              {!isSearching && searchResults.map((n, idx) => (
                <div
                  key={idx}
                  onClick={() => handleClick(n._id)}
                  className='border rounded-md p-1 cursor-pointer'
                >
                  {n.objective}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
}

export {SearchBox};