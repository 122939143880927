import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "../../../store/store";
import { setIsCurrentTaskRunning, setIsFullViewOn, setNavigatePointerNodeId } from "../../../store/actions";
import { RemaningTime } from "./RemaningTime";
import { ActionButtons } from "./ActionButtons";

interface CurrentTaskProps {
}

const CurrentTask: React.FC<CurrentTaskProps> = () => {
	const dispatch:any = useDispatch();
	const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
	
	// console.log(currentTask);
	
	const handleCurrentTask = () => {
		if (!currentTask) return;
		dispatch(setIsFullViewOn(false));
		dispatch(setNavigatePointerNodeId(currentTask?._id));
	};

	useEffect(() => {
		if (currentTask?.isSomeOneWorkingOnThis) {
			dispatch(setIsCurrentTaskRunning(true));
		}
		if(!currentTask?.isSomeOneWorkingOnThis){
			dispatch(setIsCurrentTaskRunning(false));
		}
	}, [currentTask?.isSomeOneWorkingOnThis,dispatch]);

	return (
		<div className='border-top'>
			<h5 className='mt-3'>Current task</h5>
			{/* Text area */}
			<div onClick={handleCurrentTask} className="bg-gray-100 rounded border border-gray-400 leading-normal w-full py-2 px-3 font-medium placeholder-gray-700 focus:outline-none focus:bg-white">
				{currentTask ? currentTask.objective : "Please schedule a Category of Improvement"}
			</div>

			<RemaningTime/>

			{/* Action buttons */}
			<div className='flex justify-between flex-wrap mt-3 border-bottom pb-4'>
				<ActionButtons/>
			</div>
		</div>
	);
};

export {CurrentTask};



