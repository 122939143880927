import React from 'react';
import { createRoot } from 'react-dom/client'
// import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { Provider } from 'react-redux';
import store from "./store/store";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import {isTouchedDevice}  from "./utils";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from 'react-dnd';
import './style.css';


const container = document.getElementById('root')!
const root = createRoot(container)

root.render(
  // <React.StrictMode>
    <DndProvider backend={isTouchedDevice()?TouchBackend:HTML5Backend}>
      <Provider store={store}>
        <App />
        <ToastContainer autoClose={1000}/>
      </Provider>
    </DndProvider>
  // </React.StrictMode>
);


export default root;