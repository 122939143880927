import { SET_TREE_TO_DISPLAY } from "../../constants";

interface Action {
    type: string;
    payload: any; // Define this type based on the payload of each action
}

const treeToDisplay = (state: any = null, action: Action): any => {
    switch (action.type) {
        case SET_TREE_TO_DISPLAY:
            return action.payload;

        default:
            return state;
    }
}

export default treeToDisplay;
