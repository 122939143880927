import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import { getHeightTopVal } from '../utils';

interface CurrentTimeLineProps {
    currentDay: Moment;
}

function CurrentTimeLine({ currentDay }: CurrentTimeLineProps) {
    const [startTime, setStartTime] = useState<Moment>(() => moment());
    const endTime = startTime.clone().add(2, "minutes");
    const { height, top } = getHeightTopVal({ startTime: startTime.toString(), endTime: endTime.toString() });
    const isToday = currentDay.format("DD-MM-YYYY") === startTime.format("DD-MM-YYYY");

    useEffect(() => {
        const int = setInterval(() => {
            setStartTime(moment());
        }, 60000);

        return () => {
            clearInterval(int);
        };
    }, []);

    return (
        <>
            <div
                style={{ backgroundColor: "red", position: "absolute", top, height: isToday ? height : "0" }}
                className="w-full overflow-auto"
            >
            </div>
        </>
    );
}

export default CurrentTimeLine;
