import React, { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { deleteEvent } from "../../store/actions/event";
import { NEW_EVENT } from "../../constants";
import { initialCalculations } from "../../utils";
import { RootState } from '../../store/store';

interface Task {
  _id: string;
  objective: string;
  color: string;
  isDeleted?: boolean;
}

interface LifeDivision {
  objective: string;
  children: Pick<Task, '_id' | 'objective' | 'color'>[];
}

interface SideNodesProps {
  tasks: any[] | null;
  setTasks: any;
}

function SideNodes({ tasks, setTasks }: SideNodesProps) {
  const tree = useSelector((state: RootState) => state.tree);
  const events = useSelector((state: RootState) => state.events);

  useEffect(() => {
    if (tree) {
      const lifeDivisions: LifeDivision[] = [];
      const root = JSON.parse(JSON.stringify(tree));
      initialCalculations(root);

      root.children.forEach((node: any) => {
        lifeDivisions.push({
          objective: node.objective,
          children: node.children
            .filter((n: Task) => !n.isDeleted)
            .map((n: Task) => ({ objective: n.objective, _id: n._id, color: n.color }))
        });
      });

      setTasks(lifeDivisions);
    }
  }, [tree, setTasks]);

  return (
    <div className="h-full">
      <div className='h-[calc(100%-88px)] flex flex-col overflow-y-auto'>
        {tasks && tasks.map((task, idx) => (
          <div key={idx} className='flex-1 p-2 border-r-2'>
            <h5>{task.objective}</h5>
            <div className="flex flex-col gap-2">
              {task.children?.map((node:any, idx:number) => {
                const ev:any = events?.filter((e: any) => e.node._id === node._id);
                return <Node key={idx} node={node} events={ev} />;
              })}
            </div>
          </div>
        ))}
      </div>
      <DeleteArea />
    </div>
  );
}

interface NodeProps {
  node: Task;
  events: any[];
}

function Node({ node, events }: NodeProps) {  
  const totalMinutes = events.reduce((a, b) => a + moment(b.endTime).diff(moment(b.startTime), "minutes"), 0);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: NEW_EVENT,
    item: node,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div 
      ref={drag} 
      style={{ backgroundColor: node.color, opacity: isDragging ? 0.5 : 1 }} 
      className="bg-blue-600 text-white rounded-md p-2 active:bg-red-600"
    >
      {node.objective} ({totalMinutes} <span title="Minutes Per Week" className='cursor-pointer'>MPW</span>)
    </div>
  );
}

function DeleteArea() {
  const dispatch:any = useDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: NEW_EVENT,
    drop: (item: any) => handleDrop(item),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const handleDrop = (item: { _id: string }) => {
    dispatch(deleteEvent(item._id));
  };

  return (
    <div 
      ref={drop} 
      style={{ backgroundColor: isOver ? "red" : "" }} 
      className="bg-green-200 w-full h-[10%] flex justify-center items-center"
    >
      <div className="border border-dashed border-2 border-blue-500 p-3 text-center">
        Drop here to delete
      </div>
    </div>
  );
}

export default SideNodes;
