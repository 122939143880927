import React, { useEffect, useRef } from 'react';
import "./style.css";
import { useSelector } from 'react-redux';
import moment, { Moment } from "moment";
import CurrentTimeLine from "./CurrentTimeLine";
import ScheduleDurations from "./ScheduleDurations";
import Lines from './Lines';
import TimeScale from './TimeScale';
import ExploitationDurations from "./ExploitationDurations";
import PlanningDurations from "./PlanningDurations";
import FocusBlocks from './FocusBlocks';
import { RootState } from '../../../store/store';

interface DayViewProps {
    setIsAddEventModalOpen: (isOpen: boolean) => void;
    setEventToUpdate: (event: any) => void;
    currentDay: Moment;
    exploitationDurations: Array<{ startTime: string; endTime: string }>;
    planningDurations: Array<{ startTime: string; endTime: string }>;
    calenderElVisivility: {
        scheduleDurations: boolean;
        focusBlocks: boolean;
        exploitationDurations: boolean;
        planningDurations: boolean;
    };
}

function DayView({setIsAddEventModalOpen,setEventToUpdate,currentDay,exploitationDurations,planningDurations,calenderElVisivility}: DayViewProps) {
    const todaysPlanningDurations = planningDurations?.filter(p => (
        currentDay.startOf('day').isSame(moment(p.startTime).startOf('day')) &&
        moment(p.endTime).diff(moment(p.startTime), "minutes") > 1
    ));
    
    const todaysExploitationDurations = exploitationDurations?.filter(p => (
        (currentDay.startOf('day').isSame(moment(p.startTime).startOf('day')) ||
         currentDay.startOf('day').isSame(moment(p.endTime).startOf('day'))) &&
        moment(p.endTime).diff(moment(p.startTime), "minutes") > 1
    ));
    
    const events = useSelector((state: RootState) => state.events);
    const todaysEvents:any = events?.filter((e: any) => moment(e.startTime).day() === currentDay.clone().day());
    const myRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const allLines = myRef.current?.querySelectorAll(".line");
        const minutesElapsed = moment().diff(moment().startOf("day"), "minutes");
        const margin = minutesElapsed > 1140 ? 0 : 300;
        const index = allLines && allLines[minutesElapsed - margin] ? minutesElapsed - margin : minutesElapsed;

        if (allLines && allLines[index]) {
            (allLines[index] as HTMLElement).scrollIntoView();
        }
    }, []);

    return (
        <div ref={myRef} className='flex w-full h-[calc(100vh-181px)] py-2 overflow-auto'>
            {/* time scale */}
            <TimeScale />
            {/* calendar  */}
            <div className={`h-[1440px] flex-1 flex flex-col relative`}>
                <Lines todaysEvents={todaysEvents} currentDay={currentDay} />
                {calenderElVisivility.scheduleDurations && (
                    <ScheduleDurations 
                        setIsAddEventModalOpen={setIsAddEventModalOpen} 
                        setEventToUpdate={setEventToUpdate} 
                        todaysEvents={todaysEvents} 
                        currentDay={currentDay} 
                    />
                )}
                {calenderElVisivility.focusBlocks && (
                    <FocusBlocks todaysEvents={todaysEvents} currentDay={currentDay} />
                )}
                {calenderElVisivility.exploitationDurations && (
                    <ExploitationDurations 
                        todaysExploitationDurations={todaysExploitationDurations} 
                        currentDay={currentDay} 
                    />
                )}
                {calenderElVisivility.planningDurations && (
                    <PlanningDurations todaysPlanningDurations={todaysPlanningDurations} />
                )}
                <CurrentTimeLine currentDay={currentDay} />
            </div>
        </div>
    );
}

export default DayView;
