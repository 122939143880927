import React, { useEffect, useRef, useState } from 'react';
import { Main } from "../../layout";
import { useDispatch, useSelector } from 'react-redux';
import { hierarchy, partition, select, transition } from 'd3';
import Node from './Node/Index'; // Adjust this import if necessary
import RankNodes from "../RankNodes/Index"; // Adjust this import if necessary
import { setAddNodeModalOpen, setUpdateNodeModalOpen, setCaptureParkNavigatorId, setParentIdOfNodeTobeCreated, setNodeIdToBeEdited } from '../../store/actions';
import { Loading } from "../../components";
import { RootState } from '../../store/store';

const heightOfLabels = 20;
const topTitles = ["Life", "Life Divisions", "Categories of Improvement", "Projects & Objectives"];

interface Dimensions {
  height: number;
  width: number;
}

const Index: React.FC = () => {
  const myTree = useSelector((state: RootState) => state.tree); // Adjust state type as necessary
  const data = JSON.parse(JSON.stringify(myTree));
  const containerEl = useRef<HTMLDivElement | null>(null);
  const events = useSelector((state: RootState) => state.events); // Adjust state type as necessary
  const isCompletedNodesVisible = useSelector((state: RootState) => state.globalStates.isCompletedNodesVisible); // Adjust state type as necessary
  const [dimensions, setDimensions] = useState<Dimensions>({ height: 800, width: 1200 - heightOfLabels });
  const focusRef = useRef<SVGSVGElement | null>(null); // Adjust to the appropriate type if necessary
  // const dispatch = useDispatch();
  // const navigatorId = useSelector((state: RootState) => state.globalStates.captureParkNavigatorId); // Adjust state type as necessary

  useEffect(() => {
    if (containerEl.current) {
      setDimensions({
        width: containerEl.current.clientWidth,
        height: containerEl.current.clientHeight - heightOfLabels
      });
    }
  }, [myTree]);

  // useEffect(() => {
  //   if (!navigatorId) {
  //     dispatch(setCaptureParkNavigatorId(data.children.find(n => n.isCapturePark)._id));
  //   }
  // }, [dispatch, navigatorId, data]);


  if (!data) {
    return (
      <Main>
        <div className="w-full h-full flex justify-center items-center"><Loading /></div>
      </Main>
    );
  }

  function rectHeight(d: any) { // Adjust type as necessary
    return d.x1 - d.x0 - Math.min(1, (d.x1 - d.x0) / 2) + 1;
  }

  const root = calc(data, events, dimensions, focusRef, isCompletedNodesVisible); // Ensure calc function is typed correctly



  return (
    <Main>
      <div className="hidden min-[450px]:block h-[calc(100vh-64px)] w-full" ref={containerEl}>
        {
          focusRef.current && <Tree focusRef={focusRef} dimensions={dimensions} root={root} rectHeight={rectHeight} />
        }
      </div>
    </Main>
  );
}

export default Index;




interface TreeProps {
  dimensions: { height: number; width: number };
  root: any; // Replace with the appropriate type for the tree root
  rectHeight: (d: any) => number; // Adjust the parameter type as necessary
  focusRef: any; // Adjust to the appropriate type if necessary
}

const Tree: React.FC<TreeProps> = ({ dimensions, root, rectHeight, focusRef }) => {
  const myRef = useRef<SVGGElement | null>(null);
  const [currentNodeToRank, setCurrentNodeToRank] = useState<any>(null); // Adjust type as necessary
  const navigatorId = useSelector((state: RootState) => state.globalStates.captureParkNavigatorId); // Adjust state type as necessary
  const [isLoaded, setIsLoaded] = useState(false);
  const titleContainer = useRef<HTMLDivElement | null>(null);
  const dispatch:any = useDispatch();
  

  const handleClick = (e: React.MouseEvent<SVGForeignObjectElement>, d: any) => { // Adjust d type as necessary
    if (navigatorId === d.data._id && d.parent) {
      dispatch(setCaptureParkNavigatorId(d.parent.data._id));
      return;
    }
    if (d.children || d.parent) {
      dispatch(setCaptureParkNavigatorId(d.data._id));
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<SVGSVGElement>) => {
    const key = e.key;
    if (key === "ArrowLeft" && focusRef.current?.parent) {
      dispatch(setCaptureParkNavigatorId(focusRef.current.parent.data._id));
    }
    if (key === "ArrowRight" && focusRef.current?.children?.length !== 0) {
      dispatch(setCaptureParkNavigatorId(focusRef.current.children[0].data._id));
    }
    if (key === "ArrowUp" && focusRef.current?.parent) {
      const index = focusRef.current.parent.children.findIndex((n: any) => n.data._id === focusRef.current.data._id);
      const target = focusRef.current.parent.children[index - 1];
      if (target) {
        dispatch(setCaptureParkNavigatorId(target.data._id));
      } else {
        dispatch(setCaptureParkNavigatorId(focusRef.current.parent.children[focusRef.current.parent.children.length - 1].data._id));
      }
    }
    if (key === "ArrowDown" && focusRef.current?.parent) {
      const index = focusRef.current.parent.children.findIndex((n: any) => n.data._id === focusRef.current.data._id);
      const target = focusRef.current.parent.children[index + 1];

      if (target) {
        dispatch(setCaptureParkNavigatorId(target.data._id));
      } else {
        dispatch(setCaptureParkNavigatorId(focusRef.current.parent.children[0].data._id));
      }
    }
    if (!e.ctrlKey && key === 'Enter') {
      root.each((n: any) => { // Adjust type as necessary
        if (n.data._id === navigatorId) {
          dispatch(setNodeIdToBeEdited(n.data._id));
          dispatch(setUpdateNodeModalOpen(true));
          return;
        }
      })
    }
    if (e.ctrlKey && key === 'Enter') {
      dispatch(setAddNodeModalOpen(true));
      dispatch(setParentIdOfNodeTobeCreated(navigatorId || ""));
    }
  }

  const handleRankButtonClick = (i: number) => {
    const firstEl = root.descendants().find((node: any) => node.data._id === navigatorId); // Adjust type as necessary

    if (!firstEl) {
      return;
    }

    if (i === 0) {
      setCurrentNodeToRank(firstEl);
      return;
    }
    if (i === 1) {
      if (firstEl.children[0]) {
        setCurrentNodeToRank(firstEl.children[0]);
      }
      return;
    }
  }

  useEffect(() => {
    const handleNavigate = (navigateToNode: string | undefined, isRoot: boolean) => {
      let p: any = undefined; // Adjust type as necessary

      if (navigateToNode) {
        root.each((n: any) => { // Adjust type as necessary
          if (n.data._id === navigateToNode) {
            p = n;
          }
        });
      }

      if (!p || !p.parent) {
        return;
      }

      focusRef.current = focusRef.current.data._id === p.data._id ? (p = p.parent) : p;

      root.each((d: any) => { // Adjust type as necessary
        d.target = {
          x0: ((d.x0 - p.x0) / (p.x1 - p.x0)) * dimensions.height,
          x1: ((d.x1 - p.x0) / (p.x1 - p.x0)) * dimensions.height,
          y0: d.y0 - p.y0,
          y1: d.y1 - p.y0
        }
      });

      animateNodes(false);

      function animateNodes(isRoot: boolean) {
        const t = transition().duration(725);
        const parent = select(myRef.current);

        parent.selectAll("g")
          .data(root.descendants())
          .transition(t)
          .attr('transform', (d: any) => `translate(${isRoot ? d.y0 : d.target.y0},${isRoot ? d.x0 : d.target.x0})`);

        parent.selectAll("foreignObject")
          .data(root.descendants())
          .transition(t)
          .attr('height', function (d: any) { // Adjust type as necessary
            const height = rectHeight(isRoot ? d : d.target);
            const elem: any = this;
            const bar = elem?.querySelector(".progress-bar");
            const info = elem?.querySelector(".other-info");
            const buttons = elem?.querySelector(".crud-buttons");
            if (bar && info) {
              if (height < 76) {
                bar.style.display = "none"
              } else {
                bar.style.display = "flex"
              }
              if (height < 30) {
                info.style.display = "none"
              } else {
                info.style.display = "flex";
              }
              if (height < 116) {
                buttons.style.display = "none"
              } else {
                buttons.style.display = "unset";
              }
            }

            return height;
          });
      }

      titleContainer.current?.childNodes.forEach((div: any, i: number) => {
        const text = div.querySelector(".text");
        const depth = focusRef.current.depth + i;
        text.innerHTML = depth < topTitles.length ? topTitles[depth] : `OKR L${depth - 3}`;
      });
    }

    if (isLoaded && navigatorId) {
      handleNavigate(navigatorId, false);
    } else {
      setIsLoaded(true);
    }

  }, [navigatorId, isLoaded, dimensions.height, rectHeight, focusRef, root]);

  // focus on svg element when program loads
  useEffect(() => {
    if (myRef && myRef.current?.parentElement) {
      myRef.current.parentElement.focus();
    }
  }, [myRef]);

  return (
    <>
      <div ref={titleContainer} className='flex justify-center bg-white text-xs' style={{ height: `${heightOfLabels}px` }}>
        {
          new Array(3).fill(1).map((item, i) => (
            <div key={i + item} className='flex-1 text-center flex justify-center items-center gap-2'>
              <span className='text'>{root.depth + i < topTitles.length ? topTitles[root.depth + i] : `OKR L${(root.depth - 3) - i}`}</span>
              {
                i < 2 && <button onClick={() => handleRankButtonClick(i)} className='cursor-pointer h-[15px] text-[10px] rounded-md pb-[1px] px-[5px] bg-blue-600 text-white'>Rank</button>
              }
            </div>
          ))
        }
      </div>
      <svg style={{ display: currentNodeToRank ? "none" : "" }} tabIndex={0} onKeyDown={handleKeyPress} width={dimensions.width} height={dimensions.height}>
        <g ref={myRef} transform='translate(0,0)'>
          {
            root.descendants().map((d:any) => (
              <g key={d.data._id} transform={`translate(${d.y0},${d.x0})`} >
                <foreignObject
                  className='node-container'
                  onClick={(e) => handleClick(e, d)}
                  width={(dimensions.width / 3)}
                  height={rectHeight(d)}>
                  <Node d={d} height={rectHeight(d)} setCurrentNodeToRank={setCurrentNodeToRank} />
                </foreignObject>
              </g>
            ))
          }
        </g>
      </svg>
      {
        currentNodeToRank && <RankNodes setCurrentNodeToRank={setCurrentNodeToRank} currentNodeToRank={currentNodeToRank} />
      }
    </>
  );
};

function randomInteger(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

interface INode {
  children: INode[];
  isCapturePark?: boolean;
  value?: number;
}

interface Data {
  children: INode[];
}

function calc(data: Data,events: any,dimensions: { height: number; width: number },focusRef: any,isCompletedNodesVisible: boolean) {
  const values: number[] = [];

  // Find the capture park node
  data = data.children.find((n) => n.isCapturePark) as INode;
  addValueKey(data);

  function addValueKey(node: INode) {
    if (node.children.length === 0) {
      let val = randomInteger(10, 5000);
      while (values.indexOf(val) !== -1) {
        val = randomInteger(10, 5000);
      }
      values.push(val);
      node.value = val;
    }

    if (node.children) {
      node.children.forEach((item) => {
        addValueKey(item);
      });
    }
  }

  let tempRoot:any = hierarchy(data);
  // treeCalculations(tempRoot, events, isCompletedNodesVisible); // Uncomment if needed

  tempRoot = tempRoot.sum((d:any) => d.value || 0);

  const root = partition().size([
    dimensions.height,
    (tempRoot.height + 1) * dimensions.width / 3,
  ])(tempRoot);
  
  focusRef.current = root;

  return root;
}

export { calc };





