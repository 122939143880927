import React, { useEffect, useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import moment from 'moment';


function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function Header({setEventToUpdate,startOfWeek,currentDay,setWeekAhead,setDayAhead,dayAhead,currentView,setcurrentView,setIsAddEventModalOpen,weekAhead,setCalenderElVisivility,calenderElVisivility}: any) {
  const [displayExAndPlanDur, setDisplayExAndPlanDur] = useState(false);
  const [displaySchAndFB, setDisplaySchAndFB] = useState(false);
  const isDayView = currentView === 'Day view';

  const handleViewChange = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setcurrentView(e.currentTarget.innerText);
  };

  const nextAndPrevWeek = (isPrev: boolean) => {
    if (currentView === 'Week view') {
      setWeekAhead((prev:any) => (isPrev ? prev - 1 : prev + 1));
    }
    if (currentView === 'Day view') {
      setDayAhead((prev:any) => (isPrev ? prev - 1 : prev + 1));
    }
  };

  const handleCalenderVisivilityChange = (action: string) => {
    if(setCalenderElVisivility){
      setCalenderElVisivility((prev:any) => ({
        ...prev,
        [action]: !prev[action],
      }));
    }
  };

  const handleAddEvent = () => {
    setEventToUpdate(null);
    setIsAddEventModalOpen(true);
  };

  useEffect(() => {
    if ((!isDayView && weekAhead >= 0) || (isDayView && dayAhead >= 0)) {
      setDisplaySchAndFB(true);
      if(setCalenderElVisivility){
        setCalenderElVisivility((prev:any) => ({
          ...prev,
          scheduleDurations: true,
          focusBlocks: false,
        }));
      }
    } else {
      setDisplaySchAndFB(false);
      if(setCalenderElVisivility){
        setCalenderElVisivility((prev:any) => ({
          ...prev,
          scheduleDurations: false,
          focusBlocks: false,
        }));
      }
    }

    if ((!isDayView && weekAhead <= 0) || (isDayView && dayAhead <= 0)) {
      setDisplayExAndPlanDur(true);
      if(setCalenderElVisivility){
        setCalenderElVisivility((prev:any) => ({
          ...prev,
          exploitationDurations: true,
          planningDurations: true,
        }));
      }
      
    } else {
      setDisplayExAndPlanDur(false);
      if(setCalenderElVisivility){
        setCalenderElVisivility((prev:any) => ({
          ...prev,
          exploitationDurations: true,
          planningDurations: true,
        }));
      }
    }
  }, [dayAhead, isDayView, weekAhead, setCalenderElVisivility]);

  return (
    <header className="flex flex-none items-center justify-between border-b border-gray-200 py-4 px-6">
      <div>
        <h1 className="text-lg font-semibold leading-6 text-gray-900">
          <time dateTime="2022-01-22" className="hidden sm:inline">
            {currentView === 'Day view' && (
              <>
                {dayAhead === 0 && moment().format('MMMM D, YYYY')}
                {dayAhead !== 0 && currentDay.format('MMMM D, YYYY')}
              </>
            )}
            {currentView === 'Week view' && (
              <>
                {weekAhead === 0 && moment().format('MMMM D, YYYY')}
                {weekAhead !== 0 && startOfWeek.format('MMMM D, YYYY')}
              </>
            )}
          </time>
        </h1>
        <p className="mt-1 text-sm text-gray-500">
          {currentView === 'Day view' && (
            <>
              {dayAhead === 0 && moment().format('dddd')}
              {dayAhead !== 0 && currentDay.format('dddd')}
            </>
          )}
          {currentView === 'Week view' && (
            <>
              {weekAhead === 0 && moment().format('dddd')}
              {weekAhead !== 0 && startOfWeek.format('dddd')}
            </>
          )}
        </p>
      </div>
      <div className="flex items-center">
        <div className="flex items-center rounded-md shadow-sm md:items-stretch">
          <button
            onClick={() => nextAndPrevWeek(true)}
            type="button"
            className="flex items-center justify-center rounded-l-md border border-r-0 border-gray-300 bg-white py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            title={`Prev ${currentView.split(' ')[0]}`}
          >
            <span className="sr-only">Previous {currentView.split(' ')[0]}</span>
            <img alt='Prev Button' className="h-5 w-5" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAANUlEQVR4nGNgGAWjYBRgAw0MNDb8P60N/09rw/9TEcNBOa0tQLeEZqCc1hbQPJmOglEwEgEA5zgztn+xmUkAAAAASUVORK5CYII="/>
          </button>
          <button
            type="button"
            className="hidden border-t border-b border-gray-300 bg-white px-3.5 text-sm font-medium text-gray-700 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block"
          >
            {currentView.split(' ')[0]}
          </button>
          <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
          <button
            onClick={() => nextAndPrevWeek(false)}
            title={`Next ${currentView.split(' ')[0]}`}
            type="button"
            className="flex items-center justify-center rounded-r-md border border-l-0 border-gray-300 bg-white py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
          >
            <span className="sr-only">Next {currentView.split(' ')[0]}</span>
            <img alt='Next Button' className="h-5 w-5" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAOUlEQVR4nGNgGAWjYBRQG3Qw0Bj8p7Ul/3FZ8p8GuIPWFtTTMojqaWE4A60NB4EGMDkKRsEoYEAGAADkM+50yjcKAAAAAElFTkSuQmCC"/>
          </button>
        </div>
        <div className="hidden md:ml-4 md:flex md:items-center">
          <Menu as="div" className="relative">
            <Menu.Button
              type="button"
              className="flex items-center rounded-md border border-gray-300 bg-white py-2 pl-3 pr-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
            >
              {currentView}
              <img alt='img' className="ml-2 h-5 w-5 text-gray-400" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAjUlEQVR4nO2USwqAIBRFzwJaRIMW1qBJNHHYaoMWkjZ5ggOLIjGoe8CBqNzj74EQzwjWXiNIAF0BeoToGwYVIlSK/1KKmxsCubmPaIEFmC8IOGAFupICPeAtzJ0IOOt7W1OUAdgsYM4IpOFT6fAjiShQJTwyJteRNm9jVRiSk6i28yOJV8IjY81jF99kB8OWSwQoWsJfAAAAAElFTkSuQmCC"></img>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="focus:outline-none absolute z-20 right-0 mt-3 w-36 origin-top-right overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={handleViewChange}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm no-underline'
                        )}
                      >
                        Day view
                      </span>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <span
                        onClick={handleViewChange}
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm no-underline'
                        )}
                      >
                        Week view
                      </span>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <div className="ml-6 h-6 w-px bg-gray-300" />
          <button
            onClick={handleAddEvent}
            type="button"
            className="mr-2 focus:outline-none ml-6 rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Add event
          </button>

          {displaySchAndFB && (
            <>
              <button
                onClick={() => handleCalenderVisivilityChange('focusBlocks')}
                type="button"
                className={`mr-2 rounded-md border border-transparent ${calenderElVisivility?.focusBlocks ? 'bg-indigo-600' : 'bg-indigo-600/50'} py-2 px-4 text-sm font-medium text-white shadow-sm`}
              >
                FB
              </button>
              <button
                onClick={() => handleCalenderVisivilityChange('scheduleDurations')}
                type="button"
                className={`mr-2 rounded-md border border-transparent ${calenderElVisivility?.scheduleDurations ? 'bg-indigo-600' : 'bg-indigo-600/50'} py-2 px-4 text-sm font-medium text-white shadow-sm`}
              >
                Sch
              </button>
            </>
          )}
          {displayExAndPlanDur && (
            <>
              <button
                onClick={() => handleCalenderVisivilityChange('exploitationDurations')}
                type="button"
                className={`mr-2 rounded-md border border-transparent ${calenderElVisivility?.exploitationDurations ? 'bg-indigo-600' : 'bg-indigo-600/50'} py-2 px-4 text-sm font-medium text-white shadow-sm`}
              >
                Exp Dur
              </button>
              <button
                onClick={() => handleCalenderVisivilityChange('planningDurations')}
                type="button"
                className={`rounded-md border border-transparent ${calenderElVisivility?.planningDurations ? 'bg-indigo-600' : 'bg-indigo-600/50'} py-2 px-4 text-sm font-medium text-white shadow-sm`}
              >
                Plan Dur
              </button>
            </>
          )}
        </div>
        <Menu as="div" className="relative ml-6 md:hidden">
          <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-gray-400 hover:text-gray-500">
            <span className="sr-only">Open menu</span>
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-gray-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                      onClick={handleAddEvent}
                    >
                      Create event
                    </span>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Go to today
                    </span>
                  )}
                </Menu.Item>
              </div>
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Day view
                    </span>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Week view
                    </span>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Month view
                    </span>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block px-4 py-2 text-sm no-underline'
                      )}
                    >
                      Year view
                    </span>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </header>
  );
}

export default Header;
