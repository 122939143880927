import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
  // completeTask,
  setParentTaskCompleteAlertModalOpen,
  setAddNodeModalOpen,
  // setParentIdOfNodeTobeCreated,
  // setStartTime,
  // setManuallyScheduledNodeId,
  // setCurrentTask
} from '../../store/actions';
// import moment from 'moment';
// import { SET_WERE_YOU_WORKING } from '../../constants';
import { RootState } from '../../store/store';

interface FormProps {
  e: any; // Adjust type as necessary
}

const Form: React.FC<FormProps> = ({ e }) => {    
  return (
    <form className="w-full">
      <FormData e={e} />
    </form>
  )
}

export default Form;

interface FormDataProps {
  e: any; // Adjust type as necessary
}

const FormData: React.FC<FormDataProps> = ({ e }) => {
  const dispatch:any = useDispatch();
  const parentId = useSelector((state: RootState) => state.globalStates.parentId); // Adjust type as necessary
  const tree = useSelector((state: RootState) => state.tree); // Adjust type as necessary
  const [parent, setParent] = useState<any>(null);

  
  // const parent = currentTasksParents.find((p: any) => p._id === parentId); // Adjust type as necessary
  // const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId); // Adjust type as necessary
  // const wereYouWorking = useSelector((state: RootState) => state.globalStates.wereYouWorking); // Adjust type as necessary

  const handleAddNewTask = (e:any) => {
    e.preventDefault();
    // dispatch(setParentIdOfNodeTobeCreated(parent._id));
    dispatch(setParentTaskCompleteAlertModalOpen(false));
    dispatch(setAddNodeModalOpen(true));
    
    // const isRecurring = !!currentTask.recurring.frequency;
    // const ed = currentTask.taskExploitationDurationAsArray.reduce((a: number, b: any) => {
    //   if (isRecurring) {
    //     const isToday = moment(b.startTime).isBetween(moment().startOf("day"), moment().endOf("day"));
    //     return isToday ? a + moment(b.endTime).diff(moment(b.startTime), "seconds") : a; 
    //   }
    //   return a + moment(b.endTime).diff(moment(b.startTime), "seconds");   
    // }, 0);

    // const et = (currentTask.estimatedTaskDuration * 60) - ed;
    // const popupCameUpTime = moment(currentTask?.startTime).add(et, "seconds").valueOf();

    // if (currentTask?.startTime) {
    //   const endTime = wereYouWorking ? moment().valueOf() : popupCameUpTime;
    //   dispatch(completeTask(currentTask._id, currentTask.nodeProductivityValue,endTime.toString()));
    //   dispatch(setStartTime(null)); 
    // } else {
    //   dispatch(completeTask(currentTask._id,currentTask.nodeProductivityValue));
    // }

    // if (manuallyScheduleNodeId && manuallyScheduleNodeId[manuallyScheduleNodeId.length - 1] === currentTask._id) {
    //   const remainingIds = manuallyScheduleNodeId.filter((id:string) => id !== currentTask._id);
    //   dispatch(setManuallyScheduledNodeId(remainingIds.length ? remainingIds : null));
    // }

    // dispatch(setCurrentTask(null));
    // dispatch({ action: SET_WERE_YOU_WORKING, payload: false });
  }

  const handleCompleteTask = (e:any) => {
    e.preventDefault();
    dispatch(setParentTaskCompleteAlertModalOpen(false));
    
    // const isRecurring = !!currentTask.recurring.frequency;
    // const ed = currentTask.taskExploitationDurationAsArray.reduce((a: number, b: any) => {
    //   if (isRecurring) {
    //     const isToday = moment(b.startTime).isBetween(moment().startOf("day"), moment().endOf("day"));
    //     return isToday ? a + moment(b.endTime).diff(moment(b.startTime), "seconds") : a; 
    //   }
    //   return a + moment(b.endTime).diff(moment(b.startTime), "seconds");   
    // }, 0);

    // const et = (currentTask.estimatedTaskDuration * 60) - ed;
    // const popupCameUpTime = moment(currentTask?.startTime).add(et, "seconds").valueOf();

    // if (currentTask?.startTime) {
    //   const endTime = wereYouWorking ? moment().valueOf() : popupCameUpTime;
    //   dispatch(completeTask(currentTask._id, currentTask.nodeProductivityValue,endTime.toString()));
    //   dispatch(setStartTime(null)); 
    // } 
    // else {
    //   dispatch(completeTask(currentTask._id,currentTask.nodeProductivityValue));
    // }

    // if (manuallyScheduleNodeId && manuallyScheduleNodeId[manuallyScheduleNodeId.length - 1] === currentTask._id) {
    //   const remainingIds = manuallyScheduleNodeId.filter((id:string) => id !== currentTask._id);
    //   dispatch(setManuallyScheduledNodeId(remainingIds.length ? remainingIds : null));
    // }
    // // dispatch({ action: SET_WERE_YOU_WORKING, payload: false });
    // dispatch(setCurrentTask(null));
  }

  useEffect(() => {
    function visit(d:any){
      if(d._id  === parentId){
        setParent(d);
        return;
      }
      if(d.children){
        d.children.forEach((child:any) => visit(child));
      }
    }
    visit(tree);
  }, [tree,parentId]);

  return (
    <>
      <p className="text-top">Excellent, you have completed all the current tasks for {parent?.objective}.</p>

      <div className="flex flex-col gap-2">
        <button onClick={handleCompleteTask} className='py-2 bg-green-600 text-white font-bold rounded-md'>
          Mark {parent?.objective} as completed
        </button>
        <button onClick={handleAddNewTask} className='py-2 bg-green-600 text-white font-bold rounded-md'>
          Add new task to {parent?.objective}
        </button>
      </div>
    </>
  );
}
