import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// Define the structure for each item in the list
interface Item {
  _id: string;
  color?: string;
  completedPortion: number;
  objective: string;
  estimatedTaskDuration: number;
  totalEstimatedTaskDuration?: number;
  children?: Item[];
}

// Props for the QuoteList component
interface QuoteListProps {
  list: Item[];
}

// Memoized QuoteList component
const QuoteList = React.memo<QuoteListProps>(function QuoteList({ list }) {
  return (
    <>
      {list.map((item, index) => (
        <Draggable key={item._id} draggableId={item._id} index={index}>
          {(provided:any) => {
            const color = item.color ? `bg-[${item.color}]` : '';

            return (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`${color} ${item.children?.length ? "bg-yellow-400" : "bg-green-400"} w-full border border-gray-400 mb-[8px] p-[8px] rounded-md`}
              >
                <div className="w-full h-3 rounded-md bg-blue-600/30">
                  <div style={{ width: `${item.completedPortion * 100}%` }} className="rounded-md bg-blue-600 text-white text-[8px] pl-2 text-center">
                    {parseFloat(`${item.completedPortion * 100}`).toFixed(2)}%
                  </div>
                </div>
                <p className='bg-gray-300 mt-2 p-1 rounded-md mb-1'>{item.objective}</p>
                <div className='flex'>
                  {
                    (item.children && item.children?.length > 0) && <p className="text-[12px] font-bold text-end mb-0">TED: {item.totalEstimatedTaskDuration}</p>
                  }
                  {
                    item.children?.length === 0 && <p className="text-[12px] font-bold text-end mb-0">ED: {item.estimatedTaskDuration}</p>
                  }
                </div>
              </div>
            );
          }}
        </Draggable>
      ))}
    </>
  );
});

// Props for the DragAndDropList component
interface DragAndDropListProps {
  list: Item[];
  setList: React.Dispatch<React.SetStateAction<Item[]>>;
}

function DragAndDropList({ list, setList }: DragAndDropListProps) {

  function onDragEnd(result: any) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const orderedList = reorder(
      list,
      result.source.index,
      result.destination.index
    );

    setList(orderedList);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <QuoteList list={list} />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

// Function to reorder the list based on drag-and-drop
const reorder = (list: Item[], startIndex: number, endIndex: number): Item[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export { DragAndDropList };
