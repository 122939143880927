import React from 'react';
import { getHeightTopVal } from '../utils';
import moment from 'moment';

interface ExploitationDurationProps {
  time: {
    startTime: string;
    endTime: string;
    objective?: string;
    color?: string;
  };
  currentDay: moment.Moment;
}

interface ExploitationDurationsProps {
  todaysExploitationDurations: {
    startTime: string;
    endTime: string;
    objective?: string;
    color?: string;
  }[];
  currentDay: moment.Moment;
}

function ExploitationDurations({ todaysExploitationDurations, currentDay }: ExploitationDurationsProps) {
  return (
    <>
      {todaysExploitationDurations &&
        todaysExploitationDurations.map((e, i) => (
          <ExploitationDuration key={i} time={e} currentDay={currentDay} />
        ))}
    </>
  );
}

function ExploitationDuration({ time, currentDay }: ExploitationDurationProps) {
  const isEndTimeNextDay = moment(time.startTime).add(1, 'day').day() === moment(time.endTime).day() && currentDay.date() === moment(time.startTime).date();
  const isStartTimePreviousDay = moment(time.endTime).subtract(1, 'day').day() === moment(time.startTime).day() && currentDay.date() === moment(time.endTime).date();
  const endTime:any = isEndTimeNextDay ? moment(time.startTime).endOf('day').toDate() : time.endTime;
  const startTime:any = isStartTimePreviousDay ? moment(time.endTime).startOf('day').toDate() : time.startTime;

  const { height, top } = getHeightTopVal({ ...time, startTime, endTime });
  const title = `(Exploitation) ${time?.objective}`;
  

  return (
    <div title={title} style={{ height, top, backgroundColor: time.color}} className="cursor-pointer absolute w-full bg-gray-400 overflow-hidden">
      <div className="flex gap-1 flex-col text-xs">
        <span>{time?.objective}</span>
        <span>{moment(time.startTime).format('h:mm a')} - {moment(time.endTime).format('h:mm a')} ({moment(time.endTime).diff(moment(time.startTime), "minutes")})</span>
      </div>
    </div>
  );
}

export default ExploitationDurations;
