import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  NavBar,
  SideBar
} from './layout';

import {
  Login,
  Signup,
  PageNotFound,
  SetWorkingTime,
  ICICLETree,
  Achievement,
  SetObjective,
  PastDeadlines,
  CapturePark,
  TrashCan,
  ExDuration
} from './pages';

import {
  getLoggedInUser,
  getTreeData
} from "./store/actions";

import {
  PrivateRoute
} from './components';

import {generateFireBaseToken} from "./firebasePushNotification";
import { RootState } from './store/store';
import { isMobile } from 'react-device-detect';



const App: React.FC = () => {
  const dispatch:any = useDispatch();
  
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const cutNodeId = useSelector((state: RootState) => state.globalStates.cutNodeId);
  const copyNodeId = useSelector((state: RootState) => state.globalStates.copiedNodeId);
  const isShowCaptureUIOnMobile = useSelector((state: RootState) => state.globalStates.isShowCaptureUIOnMobile);


  useEffect(() => {
    if (!loggedInUser) {
      dispatch(getLoggedInUser());
    }
    if (loggedInUser) {
      dispatch(getTreeData());
    }
  }, [dispatch, loggedInUser]);

  useEffect(() => {
    generateFireBaseToken();
  }, []);


  return (
    <>
    <BrowserRouter>
      <NavBar />
      <div style={{ cursor: (cutNodeId || copyNodeId) ? "copy" : "auto" }} className='flex flex-col md:flex-row mt-[64px]'>
        {
          (!isShowCaptureUIOnMobile || !isMobile) && <>
              <div className="bg-gray-400 h-[calc(100vh-64px)] overflow-y-auto max-[450px]:w-full w-[240px]">
                  <SideBar />
              </div>
          </>
        }
        <div className="md:flex-1 h-auto w-[calc(100vw-240px)] max-[450px]:w-full">
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/set-working-time' element={<PrivateRoute><SetWorkingTime /></PrivateRoute>} />
            <Route path='/achievement' element={<PrivateRoute><Achievement /></PrivateRoute>} />
            <Route path='/' element={<PrivateRoute><ICICLETree /></PrivateRoute>} />
            <Route path='/capture-park' element={<PrivateRoute><CapturePark /></PrivateRoute>} />
            <Route path='/set-objective' element={<PrivateRoute><SetObjective /></PrivateRoute>} />
            <Route path='/past-deadlines' element={<PrivateRoute><PastDeadlines /></PrivateRoute>} />
            <Route path='/trash-can' element={<PrivateRoute><TrashCan /></PrivateRoute>} />
            <Route path='/ex-duration/:taskId' element={<PrivateRoute><ExDuration /></PrivateRoute>} />
            <Route path='*' element={<PageNotFound />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
    </>
  );
}

export default App;





