interface Node {
  children: Node[];
  isCompleted: boolean;
  isDefered: boolean;
}

function getCurrentTask(node: Node, parentObjectives: Node[]): { currentTask: Node; parentObjectives: Node[] } {
  const tempChild = [...node.children].filter(item => !item.isCompleted && !item.isDefered);

  if (tempChild.length === 0) {
      return { currentTask: node, parentObjectives };
  } else {
      parentObjectives.push(node);
      return getCurrentTask(tempChild[0], parentObjectives);
  }
}

export { getCurrentTask };