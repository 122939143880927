import moment from "moment";

function formatGoal(arr: (string | Date)[]): string {
    const newArray = arr.map((item, i) => 
        i !== 5 ? item : moment(item).format("DD-MM-YYYY")
    );

    return newArray.join(" ");
}

export { formatGoal };