import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../components';

interface FormProps {
  e: any;
}

const Form: React.FC<FormProps> = ({ e }) => {
  const { values, handleChange, handleSubmit } = e;

  return (
    <form noValidate action="" className="space-y-8 ng-untouched ng-pristine ng-valid">
      <div className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="email" className="block text-sm">
            Email address
          </label>
          <input
            value={values.email}
            onChange={handleChange}
            type="email"
            name="email"
            id="email"
            placeholder="leroy@jenkins.com"
            className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2"
          />
        </div>
        <div className="space-y-2">
          <div className="flex justify-between">
            <label htmlFor="password" className="text-sm">
              Password
            </label>
            <Link to="/forget-password" className="text-xs hover:underline text-gray-400">
              Forgot password?
            </Link>
          </div>
          <input
            value={values.password}
            onChange={handleChange}
            type="password"
            name="password"
            id="password"
            placeholder="*****"
            className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2"
          />
        </div>
      </div>
      <Button onClick={handleSubmit} style={{padding: "12px",width: "100%"}}>
        Sign in
      </Button>
    </form>
  );
};

export default Form;
