import { fetchRequest } from "../../utils";
import { ADD_ROUTINE, DOMAIN_NAME, REMOVE_ROUTINE, SET_ROUTINES, UPDATE_ROUTINE } from "../../constants";
import { toast } from "react-toastify";

interface Routine {
    id?: string;
    name?: string;
    description?: string;
    node?:any
    // Add other properties as needed
}

interface Response<T = any> {
    success: boolean;
    message: string;
    routines?: T[];
    routine?: T;
}

export const setRoutinesOfUser = () => async (dispatch: any) => {
    try {
        const res: Response<Routine> = await fetchRequest(`${DOMAIN_NAME}/routines`, "GET", {});
        
        if (res.success) {
            dispatch({ type: SET_ROUTINES, payload: res.routines });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const addRoutine = (routine: Routine) => async (dispatch: any) => {
    try {
        const res: Response<Routine> = await fetchRequest(`${DOMAIN_NAME}/routines`, "POST", { ...routine });

        if (res.success) {
            dispatch({ type: ADD_ROUTINE, payload: res.routine });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const updateRoutine = (routine: Routine) => async (dispatch: any) => {
    try {
        dispatch({ type: UPDATE_ROUTINE, payload: routine });
    } catch (error) {
        console.error(error);
    }
}

export const removeRoutine = (routineId: string) => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/routines/${routineId}`, "DELETE", {});

        if (res.success) {
            dispatch({ type: REMOVE_ROUTINE, payload: { challengeId: routineId } });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}
