import {initializeApp} from 'firebase/app';
import {getMessaging, getToken} from 'firebase/messaging';
import {updateFireBaseTokens } from './store/actions';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig:any = {
  apiKey: "AIzaSyCtgWLp8OX-hSKU8wgfHcN24ukkKqHIrTw",
  authDomain: "xpro-33c69.firebaseapp.com",
  projectId: "xpro-33c69",
  storageBucket: "xpro-33c69.appspot.com",
  messagingSenderId: "485530416546",
  appId: "1:485530416546:web:a7edff5dc8d71e43c7aefb",
  measurementId: "G-6HCGV4VT24"
};

  

const firebase = initializeApp(firebaseConfig);

const messaging = getMessaging(firebase);


// onMessage(messaging, (payload) => {

//   if(!payload.data){
//     return;
//   }  

//   if (document.visibilityState === 'visible') {
//     const notification = new Notification(payload.data.title,{
//       body: payload.data.body,
//       icon: payload.data.icon
//     });
  
//     notification.addEventListener("click",() => {
//       window.open(window.location.origin + payload.data?.url);
//     })
//   }
// });


export const generateFireBaseToken = async () =>  {
      
      const permision = await Notification.requestPermission();


      if (permision === "granted") {
        const token = await getToken(messaging,{vapidKey: 'BNyxqenV_YXPB33_fdaI6dA2JEWfSdXPdGWzhgdgghJTLdEsgpkSd70U_7MBnNwFtSH_yT3K32KW3nKZ-aWxhfA'});
        
        const res = await updateFireBaseTokens(token);
        console.log(res,token);
        
        // console.log(res.user.firebaseMessageTokens);
        // if(res.success){
        //   const t = await messaging.subscribeToTopic(res.user.firebaseMessageTokens, "all-device");
        //   console.log("subscribed to topic",t);
        // }
      }
      else {
        console.info("Permision Denied!");
        // alert("You Denied for permision");
      }
}


