import { fetchRequest } from "../../utils";
import {   
    DOMAIN_NAME,
    ADD_CHALLENGE,
    SET_CHALLENGES,
    REMOVE_CHALLENGE,
    UPDATE_CHALLENGE
} from "../../constants";
import { toast } from "react-toastify";

interface Challenge {
    id?: string;
    name?: string;
    description?: string;
    type?: string
    node?: any
}

interface Response<T = any> {
    success: boolean;
    message: string;
    challenges?: T[];
    challenge?: T;
}

export const setChallengesOfUser = () => async (dispatch: any) => {
    try {
        const res: Response<Challenge> = await fetchRequest(`${DOMAIN_NAME}/challenges`, "GET", {});
        
        if (res.success) {
            dispatch({ type: SET_CHALLENGES, payload: res.challenges });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const addChallenge = (challenge: Challenge) => async (dispatch: any) => {
    try {
        const res: Response<Challenge> = await fetchRequest(`${DOMAIN_NAME}/challenges`, "POST", { ...challenge });
        
        if (res.success) {
            dispatch({ type: ADD_CHALLENGE, payload: res.challenge });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const updateChallenge = (challenge: Challenge) => async (dispatch: any) => {
    try {
        dispatch({ type: UPDATE_CHALLENGE, payload: challenge });
    } catch (error) {
        console.error(error);
    }
}

export const removeChallenge = (challengeId: string) => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/challenges/${challengeId}`, "DELETE", {});
        
        if (res.success) {
            dispatch({ type: REMOVE_CHALLENGE, payload: { challengeId } });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}
