import { fetchRequest } from "../../utils";
import {   
    DOMAIN_NAME,
} from "../../constants";


interface IgetCompletedNodes {
    startDate: Date,
    endDate: Date
}

export const getCompletedNodes = async ({startDate,endDate}:IgetCompletedNodes) => {
    try {
        const res = await fetchRequest(`${DOMAIN_NAME}/completed-nodes?startDate=${startDate}&endDate=${endDate}`, "GET", {});
        
        return res;

    } catch (error) {

        console.error(error);
    }
}