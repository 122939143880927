
interface IRoundButton {
	children: React.ReactNode
	style?: React.CSSProperties;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	title?: string;
}

export const RoundButton: React.FC<IRoundButton> = ({ children, onClick , style,title }) => {

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if(onClick){
			onClick(e);
		}
	}

	return <button
	            title={title || ""}
				style={style}
				onClick={handleClick}
				className="p-2 flex justify-center items-center outline-none bg-green-500 rounded-full text-white shadow-lg transform active:scale-75 active:bg-green-800 transition-transform"
			>
				{children}
			</button>
}