import React, { useEffect, useState } from 'react';
import { deleteNode, getAllTempDeletedNodes } from "../../store/actions";
import { Loading } from "../../components";
import { toast } from 'react-toastify';
import { Tree } from "react-arborist";
// import { fetchRequest } from '../../utils';

interface NodeData {
    _id: string;
    id: string;
    objective: string;
    parent?: string;
    isSelected?: boolean;
    children?: NodeData[];
}

interface TempDeletedNode extends NodeData {
    // You can add any additional properties you expect here
}

const Index: React.FC = () => {
    const [tempDeletedNodes, setTempDeletedNodes] = useState<TempDeletedNode[] | null>(null);
    const [treeData, setTreeData] = useState<NodeData[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    

    const handleDeleteNode = async (nodeId: string) => {
        const res:any = await deleteNode(nodeId,"");

        if (res.success) {
            toast.success(res.message);
            const previousNodes = tempDeletedNodes || [];
            setTempDeletedNodes(null);
            const rem = previousNodes.filter(node => !res.totalDeletedNodes.includes(node._id));
            setTempDeletedNodes(rem);
        } else {
            toast.error(res.message);
        }
    }

    useEffect(() => {
        if (tempDeletedNodes) {
            const allNodes = tempDeletedNodes;
            const parentNodes = allNodes.filter(task => {
                const isParent = allNodes.some(t => task.parent === t._id);
                return !isParent;
            });

            function buildTree(d: NodeData) {
                let children = allNodes.filter((item:any) => d.children?.includes(item._id));
                let children1 = allNodes.filter(item => item.parent === d._id);

                d.children = children.length > children1.length ? children : children1;
                d.id = d._id;

                if (d.children) {
                    d.children.forEach(child => buildTree(child));
                }
            }

            parentNodes.forEach(child => {
                buildTree(child);
            });
            setTreeData([...parentNodes]);
        }
    }, [tempDeletedNodes]);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const res:any = await getAllTempDeletedNodes();
            if (res.success) {
                setTempDeletedNodes(res.totalDeletedNodes);
            }
            setIsLoading(false);
        })()
    }, []);


    // useEffect(() => {
    //     (async () => {
    //         const res = await fetchRequest("http://localhost:5000/api/v1/nodes/temp/no-parent/nodes","GET",{});
    //         if (res.success) {
    //             setTempDeletedNodes(res.nodes);
    //         }
    //     })()
    // }, []);

    return (
        <div className='w-full h-[calc(100vh-64px)] overflow-auto'>
            <h1 className="w-full bg-red-600 text-center py-2 text-white">
                Recycle Bin
            </h1>

            <p className='text-center text-gray-400'>
                {tempDeletedNodes && <span>{tempDeletedNodes.length === 0 ? "Your Bin is Empty!" : `${tempDeletedNodes.length} Items`}</span>}
            </p>

            <div className="w-full">
                {isLoading && <div className="w-full min-h-[200px] flex justify-center items-center"><Loading /></div>}
                {!isLoading && treeData && (
                    <div className="w-full flex flex-wrap gap-2 p-4 justify-center">
                        <Tree
                            data={treeData}
                            openByDefault={false}
                            width={600}
                            height={1000}
                            indent={30}
                            rowHeight={36}
                            overscanCount={1}
                            paddingTop={30}
                            paddingBottom={10}
                            padding={25}
                            // handleDeleteNode={handleDeleteNode}
                        >
                            {Node}
                        </Tree>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Index;

interface NodeProps {
    node: any;
    style: React.CSSProperties;
    tree: any; // You can replace 'any' with a specific type if known
}

function Node({ node, style, tree }: NodeProps) {
    style.backgroundColor = node.isSelected ? "gray" : "unset";
    const formattedText = node.objective?.length > 30 ? `${node.objective.substring(0, 30)}...` : node.objective;

    const handleClick = () => {
        node.toggle();
        node.select();
    }

    return (
        <div className='flex justify-between' style={{ ...style }}>
            <div onClick={handleClick} className="flex-1">
                {node.children?.length === 0 ? "🍁" : "🗀"}
                <span title={node.objective} className='w-[80%] ml-2 overflow-hidden'>{formattedText}</span>
            </div>
            <span onClick={() => { tree.props.handleDeleteNode(node._id, node.parent) }} className='m-1 cursor-pointer bg-red-600 text-[10px] rounded-md px-2 py-1 text-white'>Delete</span>
        </div>
    );
}
