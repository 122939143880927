import Store from "../store/store";

interface Node {
    isCompleted?: boolean;
    relativeSizeOfImpactNodeValue?: number;
    relativeSpeedOfImpactNodeValue?: number;
    relativeImpactNodeValue?: number;
    relativeImpactValueNodeAsScale10to0?: number;
}

function rankNodes(nodes: Node[], by: "size" | "speed" | "both"): void {
    const state = Store.getState();
    const powerLaw = state.globalStates.powerLaw;

    if (by === "size") {
        const weightedSize = 0.4;
        nodes.forEach((item, index) => {
            const order = index + 1;
            const x = 1 / order;
            const y = Math.pow(x, powerLaw) * 10;
            item.relativeSizeOfImpactNodeValue = y * weightedSize;
        });
    }

    if (by === "speed") {
        const weightedSpeed = 0.6;
        let maxZ: number | undefined;
        nodes.forEach((item, index) => {
            const order = index + 1;
            const x = 1 / order;
            const y = Math.pow(x, powerLaw) * 10;
            item.relativeSpeedOfImpactNodeValue = y * weightedSpeed;
            item.relativeImpactNodeValue = item.relativeSpeedOfImpactNodeValue + item.relativeSizeOfImpactNodeValue!;
            if (!maxZ || maxZ < item.relativeImpactNodeValue) {
                maxZ = item.relativeImpactNodeValue;
            }
        });

        nodes.forEach((item) => {
            item.relativeImpactValueNodeAsScale10to0 = (item.relativeImpactNodeValue! / maxZ!) * 10;
        });
    }

    if (by === "both") {
        const weightedSize = 0.4;
        nodes.forEach((item, index) => {
            const order = index + 1;
            const x = 1 / order;
            const y = Math.pow(x, powerLaw) * 10;
            item.relativeSizeOfImpactNodeValue = y * weightedSize;
        });

        const weightedSpeed = 0.6;
        let maxZ: number | undefined;
        nodes.forEach((item, index) => {
            const order = index + 1;
            const x = 1 / order;
            const y = Math.pow(x, powerLaw) * 10;
            item.relativeSpeedOfImpactNodeValue = y * weightedSpeed;
            item.relativeImpactNodeValue = item.relativeSpeedOfImpactNodeValue + item.relativeSizeOfImpactNodeValue!;
            if (!maxZ || maxZ < item.relativeImpactNodeValue) {
                maxZ = item.relativeImpactNodeValue;
            }
        });

        nodes.forEach((item) => {
            item.relativeImpactValueNodeAsScale10to0 = (item.relativeImpactNodeValue! / maxZ!) * 10;
        });
    }
}

export default rankNodes;