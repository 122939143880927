import { fetchRequest } from "../../utils";
import { 
    ADD_EVENT,
    DELETE_EVENT, 
    SET_EVENTS, 
    UPDATE_EVENT,
    DOMAIN_NAME
} from "../../constants";
import { toast } from "react-toastify";

interface Event {
    id?: string;
    name?: string;
    description?: string;
    startTime?: string | Date;
    endTime?: string | Date;
    currentTime?: string | Date;
    node?: string
    // Add other properties as needed
}

interface Response<T = any> {
    success: boolean;
    message: string;
    events?: T[];
    event?: T;
}

export const getAllEventsOfLoggedInUser = () => async (dispatch: any) => {
    try {
        const res: Response<Event> = await fetchRequest(`${DOMAIN_NAME}/events/user`, "GET", {});
        
        if (res.success) {
            dispatch({ type: SET_EVENTS, payload: res.events });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const createEvent = (event: Event) => async (dispatch: any) => {
    try {
        event.currentTime = new Date();
        const res: Response<Event> = await fetchRequest(`${DOMAIN_NAME}/events`, "POST", event);
        
        if (res.success) {
            toast.success("Event Created Successfully");
            dispatch({ type: ADD_EVENT, payload: res.event });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const updateEvent = (eventId: string, event: Event) => async (dispatch: any) => {
    try {
        event.currentTime = new Date().toISOString();
        event.startTime = new Date(event.startTime as string);
        event.endTime = new Date(event.endTime as string);
        const res: Response<Event> = await fetchRequest(`${DOMAIN_NAME}/events/${eventId}`, "PUT", event);
        
        if (res.success) {
            toast.success("Event Updated Successfully");
            dispatch({ type: UPDATE_EVENT, payload: res.event });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const deleteEvent = (eventId: string) => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/events/${eventId}`, "DELETE", {});
        
        if (res.success) {
            toast.success("Event Deleted Successfully");
            dispatch({ type: DELETE_EVENT, payload: { eventId } });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const updateEventLocally = async (eventId: string, event: Event) => {
    try {
        event.currentTime = new Date().toISOString();
        const res: Response<Event> = await fetchRequest(`${DOMAIN_NAME}/events/${eventId}`, "PUT", event);
        
        return res;
    } catch (error) {
        console.error(error);
    }
}
