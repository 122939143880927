
interface IRoundButton {
	children: React.ReactNode
	style?: React.CSSProperties;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	title?: string;
}

export const Button: React.FC<IRoundButton> = ({ children, onClick , style,title }) => {

	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		if(onClick){
			onClick(e);
		}
	}

	return <button
				title={title}
				style={style}
				onClick={handleClick}
				className="w-auto px-5 py-2 flex justify-center font-bold items-center outline-none bg-green-500 rounded-md text-white shadow-lg transform active:scale-90 active:opacity-50 transition-transform"
			>
				{children}
			</button>
}
