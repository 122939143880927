import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

interface RootState {
  loggedInUser: any; // Replace `any` with the appropriate type for your loggedInUser state
}

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);

  if (!loggedInUser) {
    return <Navigate to="/login" replace />;
  }
  return <>{children}</>;
};

export default PrivateRoute;
