import {
    fetchRequest,
    setToken
} from "../../utils";
import { 
    LOGIN_USER, 
    LOGOUT_USER, 
    RESET_TREE,
    DOMAIN_NAME
} from "../../constants";
import { toast } from "react-toastify";
import { getAllEventsOfLoggedInUser } from "./event";

interface User {
    username: string;
    password: string;
}

interface Response {
    success: boolean;
    message: string;
    token?: string;
    user?: any;
}

export const loginUser = (user: User) => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/auth/login`, "POST", user);
        
        if(res.success){
            toast.success("Login Success!");
            if (res.token) {
                setToken(res.token);
            }
            dispatch(getAllEventsOfLoggedInUser());
            dispatch({ type: LOGIN_USER, payload: res.user });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const logoutUser = () => async (dispatch: any) => {
    try {
        const res: Response = await fetchRequest(`${DOMAIN_NAME}/auth/logout`, "POST", {});
        
        if(res.success){
            setToken("");
            toast.success("Logout Success!");
            dispatch({ type: LOGOUT_USER, payload: null });
            dispatch({ type: RESET_TREE, payload: null });
        } else {
            toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}
