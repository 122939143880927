import React from 'react';
import {
  AddNodeModal,
  UpdateNodeModal,
  TimingAlertModal,
  UpCommingTaskModal,
  ParentTaskCompleteAlertModal,
} from "../../components";
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface IndexProps {
  children?: React.ReactNode; // Adjust this type based on how you expect to use the children prop
  onlyModals?: boolean
}

const Index: React.FC<IndexProps> = ({ children,onlyModals }) => { 
  const isUpdateNodeModalOpen = useSelector((state:RootState) => state.globalStates.isUpdateNodeModalOpen);

  if(onlyModals){
    return <>
      <AddNodeModal />
      {
        isUpdateNodeModalOpen && <UpdateNodeModal />
      }
      <TimingAlertModal />
      <UpCommingTaskModal />
      <ParentTaskCompleteAlertModal />
    </>
  }
  
  return (
    <div className='h-[calc(100vh-64px)] w-full text-black'>
      {children}
      <AddNodeModal />
      {
        isUpdateNodeModalOpen && <UpdateNodeModal />
      }
      <TimingAlertModal />
      <UpCommingTaskModal />
      <ParentTaskCompleteAlertModal />
    </div>
  );
}

export default Index;




