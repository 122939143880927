import React from 'react';
import { getHeightTopVal } from '../utils';
import moment from 'moment';

// Define the type for individual planning duration
interface PlanningDurationProps {
  time: {
    recurringDuration?: boolean; // Optional property
    objective?: string; // Required property
    startTime: string; // Assuming ISO string format
    endTime: string; // Assuming ISO string format
    color?: string; // Optional property
  };
}

// Define the type for the props of PlanningDurations component
interface PlanningDurationsProps {
  todaysPlanningDurations: PlanningDurationProps['time'][]; // Array of planning durations
}

function PlanningDurations({ todaysPlanningDurations }: PlanningDurationsProps) {
  return (
    <>
      {todaysPlanningDurations &&
        todaysPlanningDurations.map((e, i) => {
          return <PlanningDuration key={i} time={e} />;
        })}
    </>
  );
}

export default PlanningDurations;

function PlanningDuration({ time }: PlanningDurationProps) {
  const { height, top } = getHeightTopVal(time);
  const title = `${time.recurringDuration ? '(Recurring)' : '(Planning)'} ${time?.objective}`;

  return (
    <>
      <div
        title={title}
        style={{ height, top, backgroundColor: time.color || 'green', overflow: 'hidden' }}
        className="cursor-pointer absolute w-full bg-gray-400 overflow-hidden"
      >
        <div className="flex gap-1 flex-col text-xs">
          <span>{time?.objective}</span>
          <span>
            {moment(time.startTime).format('h:mm a')} - {moment(time.endTime).format('h:mm a')} (
            {moment(time.endTime).diff(moment(time.startTime), 'minutes')}
            )
          </span>
        </div>
      </div>
    </>
  );
}
