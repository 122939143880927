import { fetchRequest } from "../../utils";
import { 
    DOMAIN_NAME
} from "../../constants"
import {IExploitationDuration} from "../../types/exploitationDuration";
import { getTreeData } from "../../pages/ICICLETree/Index";


interface IRes {
    success: boolean;
    message: string;
}

interface IGetExploitationDurationsOfLoggedInUser {
    startDate: Date;
    endDate: Date;
}

export const getExploitationDurationsOfLoggedInUser = async ({startDate,endDate}:IGetExploitationDurationsOfLoggedInUser) =>  {

    interface Res extends IRes {
        exploitationDurations: IExploitationDuration[]
    }

    try {
        const res: Res = await fetchRequest(`${DOMAIN_NAME}/exploitation-durations/user?startDate=${startDate}&endDate=${endDate}`, "GET", {});
        return res;
        
    } catch (error) {
        console.error(error);
    }
}

export const getBreadCrumbsOfLoggedInUser = async () =>  {

    interface Res extends IRes {
        exploitationDurations: IExploitationDuration[]
    }

    try {
        const res: Res = await fetchRequest(`${DOMAIN_NAME}/exploitation-durations/bread-crumbs`, "GET", {});
        return res;
        
    } catch (error) {
        console.error(error);
    }
}

export const getExploitationDurationsByNodeId = async (nodeId: string) =>  {

    interface Res extends IRes {
        exploitationDurations: IExploitationDuration[]
    }

    try {
        const res: Res = await fetchRequest(`${DOMAIN_NAME}/exploitation-durations/${nodeId}`, "GET", {});
        return res;
        
    } catch (error) {
        console.error(error);
    }
}

interface ICreateExploitationDuration {
    currentTask: any
}

export const createExploitationDuration = async (exploitationDuration: ICreateExploitationDuration) => {

    interface Res extends IRes {
        exploitationDuration: IExploitationDuration
    }

    let node:any = undefined;
    const treeData = getTreeData(); // Ensure getTreeData is defined and returns the correct type

    // find node from tree 
    if (treeData) {
        test(treeData);
        function test(d: any) {
                    if(d.data._id === exploitationDuration.currentTask._id){
                        node = d;
                        return 
                    }

                    if (d.children) {
                        d.children.forEach((child:any) => test(child));
                    }
        }
    }
    else {
                return;
    }
    
    const objectivesTillLifeDivisions: Array<string> = []; 
    const nodesTillLifeDivisions: Array<string> = []; 

    let temp = node;

    while (temp.parent) {
        objectivesTillLifeDivisions.unshift(temp.data.objective);
        nodesTillLifeDivisions.unshift(temp.data._id);
        temp = temp.parent;
    }
    
    if(objectivesTillLifeDivisions.length === 0 || nodesTillLifeDivisions.length === 0 || !exploitationDuration?.currentTask?.startTime){
        return; 
    }

    const data = {
        objectivesTillLifeDivisions,
        nodesTillLifeDivisions,
        startTime: exploitationDuration.currentTask.startTime,
        endTime: new Date()
    }
    
    try {

        const res: Res = await fetchRequest(`${DOMAIN_NAME}/exploitation-durations`, "POST", data);
        
        return res;
        
    } catch (error) {

        console.error(error);
        
    }
}


