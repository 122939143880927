import React,{ useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from "../../store/actions/auth";
import { updateOrCreateState } from "../../store/actions/state";
import { APP_VERSION } from '../../constants/constants';
import { SearchBox } from './components/SearchBox';
import moment from 'moment';
import { RootState } from '../../store/store';
import { Button } from '../../components';
import { getCompletedNodes, setIsShowCaptureUIOnMobile } from '../../store/actions';
import { isMobile } from 'react-device-detect';

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
}

const navigation: NavigationItem[] = [
  { name: 'Capture', href: '/', current: true },
  { name: 'Capture Park', href: '/capture-park', current: true },
  { name: 'Schedule', href: '/set-working-time', current: false },
  { name: 'Achievement', href: '/achievement', current: false },
  { name: 'Trash', href: '/trash-can', current: false }
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Navbar: React.FC = () => {
  const [active, setActive] = useState<string>("Manage Client Users");
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser);
  const tree = useSelector((state: RootState) => state.tree);
  const navigatePointerNodeId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);
  const isShowCaptureUIOnMobile = useSelector((state: RootState) => state.globalStates.isShowCaptureUIOnMobile);
  const navigator = useNavigate();
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState<string>("");
  const dispatch:any = useDispatch();
  const [totalProductivityPoints, setTotalProductivityPoints] = useState<number>(0);


  useEffect(() => {
    if (location.pathname !== "/login") {
      setPrevLocation(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (loggedInUser && location.pathname === "/login") {
      navigator(prevLocation);
    }
  }, [loggedInUser, navigator, location.pathname, prevLocation]);

  const handleLogOut = () => {
    dispatch(logoutUser());
  }

  useEffect(() => {
    let time:any = undefined;
    if(tree){
      time = setTimeout(() => {
        if (navigatePointerNodeId && navigatePointerNodeId !== tree._id) {
          updateOrCreateState(navigatePointerNodeId,"");
        }
      }, 5000);
    }

    return () => clearTimeout(time);
  }, [navigatePointerNodeId, tree]);

  useEffect(() => {
    (async () => {
        const res = await getCompletedNodes({startDate: moment().startOf("day").toDate(),endDate: moment().endOf("day").toDate()});

        if(res?.success){
            const tp = res.nodes.reduce((a:number,b:any) => {
                if(b.isRecurring || b.recurring?.frequency){
                    const PP = b.productivityPointsHistory.find((pp:any) => moment(pp.date).startOf('day').isSame(moment().startOf("day")))?.productivityPoints || 0;
                    return a + PP;
                }
                return a + b.productivityPoints 
            },0);
            setTotalProductivityPoints(tp);
        }
    })()
  }, [tree]);

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800 fixed w-full top-0 z-50">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 min-h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu Button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <img className="block h-6 w-6" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAh0lEQVR4nO2USw6AIAwF5xIlckQ9riw8Dm4wMYZAy8e4cFYs2vcKLYWfr7ICzhDvUo6KDYhAUJq4FBtTbhUB9pRwAN4Qu2hvoTGRVnGNifSKl0xklHiuieFxtkxaEblVPazymoEfJe5mPpFkGmr5J2bxi24TzShKq4llzqXFZPqym76uf97lBItjSzKPDXfjAAAAAElFTkSuQmCC" alt='icon'/>
                    ) : (
                      <img className='block h-6 w-6' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAT0lEQVR4nO3WwQkAIAwDwIzezesWKuEO8jf4aBIAHpmSZEuS1w9QJK0/MiUBAPLBRTbj0zoatySpKTIlAQDywUU249M6GrckqSkyJQEg1x3OPOvHECpbTAAAAABJRU5ErkJggg==" alt='icon'/>
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center gap-4">
                    <NavLink to="/" className="no-underline text-white text-lg">
                      10XPro<span className='bg-white text-black p-1 text-[10px] font-bold ml-1 rounded-lg'>v{APP_VERSION.replace(/\{\{(.*?)\}\}/, '$1')}</span>
                    </NavLink>
                    {
                      <div title="Daily Productivity Points" className='text-white'>PP: {parseFloat(`${totalProductivityPoints}`).toFixed(2)}</div>
                    }
                    {
                      isMobile && <>
                          <button 
                            onClick={()=>{
                              if(isShowCaptureUIOnMobile){
                                dispatch(setIsShowCaptureUIOnMobile(false));
                                return;
                              }
                              dispatch(setIsShowCaptureUIOnMobile(true));
                            }}  
                            className='text-white bg-blue-200 w-[20px] h-[20px]'
                          />
                      </>
                    }
                    
                  </div>
                  <div className="flex-1 hidden sm:ml-6 sm:block">
                    <div className="flex space-x-4 items-center justify-end">
                      <div className="flex-1 flex-wrap flex justify-center gap-1">
                        {loggedInUser && loggedInUser.role && navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href}
                            onClick={() => setActive(item.name)}
                            className={classNames(
                              item.name === active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'px-3 py-2 rounded-md text-sm font-medium no-underline'
                            )}
                          >
                            {item.name}
                          </NavLink>
                        ))}
                        <SearchBox />
                      </div>
                      {/* node.productivityPoints */}
                      <div className="flex items-center gap-5">
                        
                        {loggedInUser && loggedInUser.role ? (
                          <>
                          <Button style={{backgroundColor: "#ff0000"}} onClick={handleLogOut}>
                            Logout
                          </Button>
                          </>
                        ) : (
                          <>
                            <NavLink
                              to="/login"
                              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                            >
                              Login
                            </NavLink>
                            <NavLink
                              to="/signup"
                              className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                            >
                              Signup
                            </NavLink>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <div className='flex gap-3 items-center'></div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 mt-4 px-2 pt-2 pb-3 flex flex-col gap-2">
                {loggedInUser && loggedInUser.role && navigation.map((item) => (
                  <div className='text-center' key={item.name}>
                    <NavLink
                      to={item.href}
                      onClick={() => setActive(item.name)}
                      className={classNames(
                        item.name === active ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                        'px-3 py-2 rounded-md text-sm font-medium no-underline'
                      )}
                    >
                      {item.name}
                    </NavLink>
                  </div>
                ))}

                {loggedInUser && loggedInUser.role && (
                  <Button style={{backgroundColor: "#ff0000",padding: "12px"}} onClick={handleLogOut}>
                      Logout
                  </Button>
                )}

                {!loggedInUser && (
                  <>
                    <div className='text-center mb-2'>
                      <NavLink
                        to="/login"
                        className="bg-blue-700 text-white hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                      >
                        Login
                      </NavLink>
                    </div>
                    <div className='text-center '>
                      <NavLink
                        to="/signup"
                        className="bg-blue-700 text-white text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline"
                      >
                        Signup
                      </NavLink>
                    </div>
                  </>
                )}

                {loggedInUser && (
                  <div className='text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium no-underline'>
                    {loggedInUser.forename} {loggedInUser.surname}
                  </div>
                )}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}

export default Navbar;
