import getToken from "./getToken";

type FetchMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';


const fetchRequest = async (url: string, method: FetchMethod, data: any): Promise<any> => {
  if (!url || !method) {
    return "url and method are required to send request";
  }

  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${getToken()}`,
    'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
  };

  if (method === 'GET') {
    const res = await fetch(url, { method, headers });
    const resData = await res.json();
    return resData;
  }

  const res = await fetch(url, {
    method,
    headers,
    body: JSON.stringify(data)
  });

  const resData = await res.json();
  return resData;
};

export default fetchRequest;