import React from 'react';
import moment from 'moment';
import { NEW_EVENT } from '../../../constants';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import { createEvent, updateEvent } from '../../../store/actions/event';

interface LinesProps {
  date?: Date; // Optional date prop
  todaysEvents?: any;
  currentDay?: any
}

function Lines({ date }: LinesProps) {
  return (
    <>
      {new Array(1440).fill(1).map((_, i) => (
        <Line key={i} i={i} date={date} />
      ))}
    </>
  );
}

export default Lines;

interface LineProps {
  i: number; // Line index
  date?: Date; // Optional date prop
}

function Line({ i, date }: LineProps) {
  const dispatch:any = useDispatch();
  const [{ isOver }, drop] = useDrop(() => ({
    accept: NEW_EVENT,
    drop: (item: any) => (date ? handleDropWeek(item, i, date) : handleDrop(item, i)),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const handleDrop = (item: any, i: number) => {
    const startTime = convertMinutesToDate(i);
    const endTime = convertMinutesToDate(i + 30);

    if (!item.isUpdate) {
      dispatch(createEvent({ startTime, endTime, node: item._id }));
      return;
    }

    const duration = moment.duration(moment(item.endTime).diff(moment(item.startTime)));
    const newEndTime = moment(startTime).add(duration.asMinutes(), 'minutes');

    if (moment().isBetween(moment(startTime), newEndTime)) {
      dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
      return;
    }

    dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
  };

  const handleDropWeek = (item: any, i: number, date: Date) => {
    const startTime = convertMinutesToDate(i, new Date(date));
    const endTime = convertMinutesToDate(i + 30, new Date(date));

    // Create event if drag object is dragged from sidebar 
    if (!item.isUpdate) {
      dispatch(createEvent({ startTime, endTime, node: item._id }));
      return;
    }

    const duration = moment.duration(moment(item.endTime).diff(moment(item.startTime)));
    const newEndTime = moment(startTime).add(duration.asMinutes(), 'minutes');

    dispatch(updateEvent(item._id, { startTime, endTime: newEndTime.toDate() }));
  };

  return (
    <div
      ref={drop}
      className="line"
      style={{
        backgroundColor: isOver ? 'blue' : `${i % 30 === 0 ? 'rgb(243 244 246)' : ''}`,
        height: '1px',
        width: '100%',
      }}
    />
  );
}

function convertMinutesToDate(minutes: number, date?: Date): Date {
  if (minutes < 0 || minutes > 1440) {
    throw new Error('Invalid input: minutes must be in the range of 0 to 1440.');
  }

  const now = date || new Date();
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;

  now.setHours(hours, mins, 0, 0);
  
  return now;
}
