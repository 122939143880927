import React from 'react';

interface ProgressBarProps {
  value: number;
  bg?: string;
  text?: string;
  fontSize?: string | number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ value, bg, text, fontSize }) => {
  return (
    <div className="flex items-center w-full bg-gray-500 h-full rounded-full dark:bg-gray-700 relative">
      <div 
        className={`${bg || "bg-blue-600"} h-full font-medium text-blue-100 text-center p-0.5 leading-none rounded-full`} 
        style={{ width: `${value}%` }} 
      />
      <div 
        style={{ fontSize }} 
        className="absolute w-full text-center text-xs text-white font-medium"
      >
        {text || `${value.toString().slice(0, 4)}%`}
      </div>
    </div>
  );
}

export default ProgressBar;
