import moment from 'moment';
import React, { ChangeEvent } from 'react';

interface TimingData {
  startDate?: string;
  endDate?: string;
  startTime?: string;
  endTime?: string;
  frequency?: string;
  days?: number[];
}

interface RecuringFormProps {
  setTimingData: React.Dispatch<React.SetStateAction<TimingData>>;
  timingData: TimingData;
}

const RecuringForm: React.FC<RecuringFormProps> = ({ setTimingData, timingData }) => {

  const handleTimingDataChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (name === "frequency" && value === "daily") {
      setTimingData(prev => ({
        ...prev,
        [name]: value,
        days: new Array(7).fill(1).map((_, i) => i)
      }));
      return;
    }

    setTimingData(prev => ({
      ...prev,
      [name]: value
    }));
  }

  return (
    <>
      <div className="mb-3 border-t">
        <div className='mt-2'>
          <label htmlFor="start" className="font-bold">Start Date</label>
          <input
            type="date"
            name="startDate"
            value={timingData?.startDate ? moment(timingData?.startDate).format("YYYY-MM-DD") : ""}
            className="border w-full p-1 rounded-md"
            id="startDate"
            required
            onChange={handleTimingDataChange}
          />
        </div>
        <div className='mt-2'>
          <label htmlFor="end" className="font-bold">End Date</label>
          <input
            type="date"
            name="endDate"
            value={timingData?.endDate ? moment(timingData?.endDate).format("YYYY-MM-DD") : ""}
            className="border w-full p-1 rounded-md"
            id="endDate"
            required
            onChange={handleTimingDataChange}
          />
        </div>
      </div>
      {/* <div className="mb-3">
        <label htmlFor="time" className="form-label">Start Time</label>
        <input
          type="time"
          name="startTime"
          value={timingData?.startTime || ""}
          className="form-control"
          id="startTime"
          onChange={handleTimingDataChange}
          required
        />
        <label htmlFor="time" className="form-label">End Time</label>
        <input
          type="time"
          name="endTime"
          value={timingData?.endTime || ""}
          className="form-control"
          id="endTime"
          onChange={handleTimingDataChange}
          required
        />
      </div> */}
      <div className="mb-3 mt-2">
        <label htmlFor="frequency" className="font-bold">Frequency</label>
        <div className="border p-1 rounded-md" id="frequency">
          <select
            id="frequency"
            name="frequency"
            value={timingData?.frequency || ""}
            className="w-full"
            required
            onChange={handleTimingDataChange}
          >
            <option value="">Please Select Frequency</option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
          </select>
        </div>
        <div className="mt-4 flex gap-2">
          {
            timingData?.frequency && (
              <>
                {new Array(7).fill(1).map((_, i) => (
                  <DayComp timingData={timingData} setTimingData={setTimingData} key={i} day={i} />
                ))}
              </>
            )
          }
        </div>
      </div>
    </>
  );
}

export default RecuringForm;

interface DayCompProps {
  day: number;
  setTimingData: React.Dispatch<React.SetStateAction<TimingData>>;
  timingData: TimingData;
}

const DayComp: React.FC<DayCompProps> = ({ day, setTimingData, timingData }) => {
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  const handleDayChange = (d: number) => {
    if (timingData.frequency === "weekly") {
      setTimingData(prev => {
        const index = prev.days?.indexOf(d) ?? -1;
        return {
          ...prev,
          days: index === -1 ? [...(prev.days || []), d] : (prev.days || []).filter(item => item !== d)
        }
      });
    }
  }

  return (
    <div onClick={() => handleDayChange(day)} className={`${timingData.days?.indexOf(day) !== -1 ? "bg-gray-600 text-white" : ""} border h-8 w-8 rounded-md cursor-pointer flex justify-center items-center font-bold`}>
      {weekDays[day]}
    </div>
  );
}
