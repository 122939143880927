import React from "react";

const Loader: React.FC = () => {
  return (
    <div className="flex w-full h-full flex-col items-center justify-center">
      <h2>Loading...</h2>
    </div>
  );
};

export default Loader;
