import React, { useState } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import Menu from "./Menu";
import TedTab from "./TedTab";
import EtdTab from "./EtdTab";
import TpdTab from "./TpdTab";
import { setUpdateNodeModalOpen, setNavigatePointerNodeId, setNodeIdToBeEdited } from "../../store/actions/globalStates";
import { useDispatch } from "react-redux";
import { useDateFormet } from "../../Hooks";



interface NodeProps {
    d: any;
    focusedNodeId?: string;
}

const Node: React.FC<NodeProps> = ({ d, focusedNodeId }) => {
    const { data } = d;
    const deadLineBg = data.deadline && new Date(data.deadline).getTime() < new Date().getTime() ? "bg-red-500 text-white" : "";
    const tempDeadLineBg = data.tempDeadline && new Date(data.tempDeadline).getTime() < new Date().getTime() ? "bg-red-500 text-white" : "";
    const dateFormet = useDateFormet();
    const dispatch:any = useDispatch();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string>("objective");
    const [previousActiveTab, setPreviousActiveTab] = useState<string>("objective");

    const handleClick = (e: React.MouseEvent<HTMLSpanElement>) => {
        const value = e.currentTarget.getAttribute("data-value");
        if (value) {
            setActiveTab(value);
            setPreviousActiveTab(value);
        }
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLSpanElement>) => {
        const value = e.currentTarget.getAttribute("data-value");
        if (value) {
            setActiveTab(value);
        }
    };

    const handleMouseLeave = () => {
        setActiveTab(previousActiveTab);
    };

    const handleOnTextClick = () => {
        dispatch(setUpdateNodeModalOpen(true));
        dispatch(setNodeIdToBeEdited(data._id))
    };

    const handleTempDeadlineClick = () => {
        if (data.inheritedFrom) {
            dispatch(setNavigatePointerNodeId(data.inheritedFrom));
        }
    };

    return (
        <div className={`${data.children?.length ? "bg-yellow-400" : "bg-green-400"} ${(data.isCompleted || data.isDeleted) ? "opacity-25" : ""} ${data.isDeleted ? "bg-red-600" : ""} p-1 h-[120px] focus:border-none ${focusedNodeId === data._id ? "bg-orange-400 opacity-100" : ""}`}>
            <div className="flex gap-5 justify-between">
                <div className="flex">
                    {d.depth === 2 && (
                        <span
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleClick}
                            className={`${activeTab === "vision" ? "bg-green-800" : "bg-green-800/50"} text-white cursor-pointer px-[5px] text-[10px]`}
                            data-value="vision"
                        >
                            Vision
                        </span>
                    )}
                    {d.depth === 3 && (
                        <span
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            onClick={handleClick}
                            className={`${activeTab === "mission" ? "bg-green-800" : "bg-green-800/50"} text-white cursor-pointer px-[5px] text-[10px]`}
                            data-value="mission"
                        >
                            Mission
                        </span>
                    )}
                    <span
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleClick}
                        className={`${activeTab === "objective" ? "bg-green-800" : "bg-green-800/50"} text-white cursor-pointer px-[5px] text-[10px]`}
                        data-value="objective"
                    >
                        Objective
                    </span>
                    <span
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={handleClick}
                        className={`${activeTab === "result" ? "bg-green-800" : "bg-green-800/50"} text-white cursor-pointer px-[5px] text-[10px]`}
                        data-value="result"
                    >
                        Key Results
                    </span>
                </div>
                <div className="flex-1 w-[40%]">
                    <ProgressBar value={data.completedPortion * 100} />
                </div>
            </div>
            <div className="bg-gray-200 px-3 mt-1 rounded-md h-[44px] overflow-y-auto" onClick={handleOnTextClick}>
                {activeTab === "objective" ? data["objectiveArr"]?.join(" ") || data[activeTab] : data[activeTab] || "Not Set"}
            </div>
            
            <div className="flex gap-2 h-10 mt-1 w-full text-[12px] font-bold">
                <EtdTab d={d} handleOnTextClick={handleOnTextClick} />
                <TedTab d={d} handleOnTextClick={handleOnTextClick} />
                <TpdTab d={d} handleOnTextClick={handleOnTextClick} />
                <div className="w-[100px]">
                    <div className="flex">
                        <span className="px-1">NPV/NPWV</span>
                    </div>
                    <div onClick={handleOnTextClick} className="text-center bg-gray-200 mt-1">
                        {data.nodeProductivityValue?.toString().slice(0, 5)} / {data.nodeProductivityWeightedValue?.toString().slice(0, 5)}
                    </div>
                </div>
                <div className="w-[100px]">
                    <div className="flex">
                        <span className="px-1">Deadline</span>
                    </div>
                    <div onClick={handleOnTextClick} className={`text-center bg-gray-200 mt-1 ${deadLineBg}`}>
                        {dateFormet(data.deadline || null) || "Not Set"}
                    </div>
                </div>
                <div className="w-[100px]">
                    <div className="flex">
                        <span className="px-1">TD</span>
                    </div>
                    <div onClick={handleTempDeadlineClick} className={`text-center bg-gray-200 mt-1 ${tempDeadLineBg}`}>
                        {dateFormet(data.tempDeadline || null) || "Not Set"}
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <svg onClick={() => setIsMenuOpen(true)} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                      <path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 Z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 Z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 Z"></path>
                    </svg>
                </div>
            </div>

            {isMenuOpen && <Menu setIsMenuOpen={setIsMenuOpen} d={d} />}
        </div>
    );
};

export default Node;
