export const colors = [
    "#1f77b4",
    "#ff7f0e",
    "#e377c2",
    "#d62728",
    "#9467bd",
    "#8c564b",
    "#7f7f7f",
    "#bcbd22",
    "#2ca02c",
    "#17becf",
    "#aec7e8",
    "#ffbb78",
    "#98df8a",
    "#ff9896",
    "#c5b0d5",
    "#c49c94",
    "#f7b6d2",
    "#c7c7c7",
    "#dbdb8d",
    "#9edae5",
    "#8c6d31",
    "#bd9e39",
    "#ad494a",
    "#31708f",
    "#66c2a5",
    "#fc8d62",
    "#8da0cb",
    "#e78ac3",
    "#a6d854",
    "#ffd92f"
  ];