import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import { completeTaskLocal, setCurrentTask, setManuallyScheduledNodeId, setParentIdOfNodeTobeCreated, setParentTaskCompleteAlertModalOpen, setTimingAlertModalOpen } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { SET_CURRENT_TASK, SET_IS_PAUSED, SET_WERE_YOU_WORKING, UPDATE_NODE } from '../../constants';
import { toast } from 'react-toastify';
import { RootState } from '../../store/store';
import { confettiRise } from '../../assets/sounds';
import { getTreeData } from '../../pages/ICICLETree/Index';

interface FormProps {
  e: any; // Replace `any` with the appropriate type for your form values
  // handleCompleteBtn: (isWorking: string) => void;
}

const Form: React.FC<FormProps> = ({ e }) => {
  return (
    <form className="w-full">
      <FormData e={e}/>
      <div className="flex items-center justify-end py-6 border-t border-gray-200 rounded-b dark:border-gray-600">
        <button 
          onClick={e.handleSubmit} 
          data-modal-hide="defaultModal" 
          type="button" 
          className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Add Extra Time
        </button>
      </div>
    </form>
  );
};

export default Form;

interface FormDataProps {
  e: FormikProps<any>; // Replace `any` with the appropriate type for your form values
  // handleCompleteBtn: (isWorking: string) => void;
}

const FormData: React.FC<FormDataProps> = ({ e }) => {
  const { values, handleChange } = e;

  const dispatch:any = useDispatch();
  const manuallyScheduleNodeId = useSelector((state: RootState) => state.globalStates.manuallyScheduleNodeId);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask); // Replace `any` with the appropriate type
  const currentTasksParents = useSelector((state: RootState) => state.globalStates.currentTasksParents); // Replace `any` with the appropriate type
  const progress = [
    {
      label: "No real progress",
      percentage: 33,
      bg: "bg-red-500"
    },
    {
      label: "Made Progress but didn't complete",
      percentage: 33,
      bg: "bg-yellow-500"
    },
    {
      label: "Completed",
      percentage: 40,
      bg: "bg-green-500"
    },
  ];

  const handleCompleteTask = async () => {
    const isWorking = values.isWorking;
    const wereYouWorking = isWorking === "Yes";

    const treeData = getTreeData();
  
    function ifParentTaskHasOneChild(){
        const children = treeData.descendants().find((n:any) => n.data._id === currentTask.parent).children.filter((c:any) => !c.data.isCompleted && !c.data.isTempCompleted);;
        if (children?.length === 1) {
            dispatch({ type: SET_WERE_YOU_WORKING, payload: wereYouWorking });
            dispatch(setTimingAlertModalOpen(false));
            dispatch(setParentTaskCompleteAlertModalOpen(true));  
        }
        else {
          dispatch(setCurrentTask(null));
        }
    }
    
    // const isRecurring = !!currentTask.recurring.frequency;
    // const ed = currentTask.taskExploitationDurationAsArray.reduce((a: number, b: any) => {
    //   if (isRecurring) {
    //     const isToday = moment(b.startTime).isBetween(moment().startOf("day"), moment().endOf("day"));
    //     if (isToday) {
    //       return a + moment(b.endTime).diff(moment(b.startTime), "seconds");
    //     }
    //     return a; 
    //   }
    //   return a + moment(b.endTime).diff(moment(b.startTime), "seconds");
    // }, 0);

    // const et = (currentTask.estimatedTaskDuration * 60) - ed;
    // const popupCameUpTime = moment(currentTask?.startTime).add(et, "seconds").valueOf();

    // const endTime = wereYouWorking ? new Date().getTime() : popupCameUpTime;

    setIsSubmiting(true);
    const res:any = await completeTaskLocal(currentTask._id,currentTask.nodeProductivityValue, new Date().toDateString(), undefined, wereYouWorking);
    
    if (res.success) {
      ifParentTaskHasOneChild();
      confettiRise.volume = .1;
      confettiRise.play();
      toast.success(res.message);
      dispatch(setTimingAlertModalOpen(false));
      dispatch({ type: SET_IS_PAUSED, payload: false });
      dispatch({ type: UPDATE_NODE, payload: {...res.node,startTime: null} });
      const remainingIds = manuallyScheduleNodeId?.filter((_id:any) => _id !== res.node._id);
      dispatch(setManuallyScheduledNodeId(remainingIds));
      dispatch({ type: SET_CURRENT_TASK, payload: res.node });
      dispatch(setParentIdOfNodeTobeCreated(currentTask.parent));
    } 
    else {
      dispatch(setTimingAlertModalOpen(true));
      toast.error(res.message);
    }
    setIsSubmiting(false);
  }

  return (
    <>
      <div className="mb-4">
        <p>Were you still working on this task at the time of this pop-up alert?</p>
        <div className="flex gap-5">
          <div className="flex items-center">
            <input 
              onChange={handleChange} 
              value={"Yes"} 
              checked={values.isWorking === "Yes"} 
              id="default-radio-1" 
              type="radio"  
              name="isWorking" 
            />
            <label htmlFor="default-radio-1" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Yes</label>
          </div>
          <div className="flex items-center">
            <input 
              onChange={handleChange} 
              value={"No"} 
              checked={values.isWorking === "No"} 
              id="default-radio-2" 
              type="radio"  
              name="isWorking" 
            />
            <label htmlFor="default-radio-2" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
          </div>
        </div>
      </div>
      <div className="mb-14">
        <p>Rate your progress on {currentTask?.objective} (think 'impact' {currentTasksParents ? currentTasksParents[0]?.objective : ""}):</p>
        
        <div className="">
          <div className="flex justify-between flex-wrap">
            {
              new Array(11).fill(1).map((_, i) => (
                <div className="flex flex-col items-center" key={i}>
                  <label htmlFor="">{i * 10}%</label>
                  <input 
                    type="radio" 
                    onChange={handleChange} 
                    value={i * 10} 
                    name='okrGradePercentage' 
                  />
                </div>
              ))
            }
          </div>
          <div className="flex mt-2">
            {
              progress.map(item => (
                <div key={item.label} className={`w-[${item.percentage}%] h-2`}>
                  <p className={`${item.bg} h-2 mb-1`} />
                  <p className='text-center text-xs'>{item.label}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>

      <p className="text-top">Is this task completed enough to move to the next task?</p>

      <div className="flex justify-between mb-2">
        <div className="flex items-center">
          <button 
            onClick={handleCompleteTask} 
            data-modal-hide="defaultModal" 
            type="button" 
            className="text-white bg-green-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            {
              isSubmiting? "Completing...":"Task Completed"
            }
          </button>
        </div>
        <div className="">
          <label htmlFor="minutes" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">No, continue current task for</label>
          <select 
            value={values.minutes} 
            onChange={handleChange} 
            name='minutes' 
            id="minutes" 
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
            {
              window.location.hostname === "localhost" && <option value={1}>{1} Min</option>
            }
            {
              new Array(5).fill(1).map((_, i) => (
                <option key={i} value={(i + 1) * 5}>{(i + 1) * 5} Mins</option>
              ))
            }
          </select>  
        </div>
      </div>
    </>
  );
};
