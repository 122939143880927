import React from 'react';
import { Button } from '../../components';

interface FormProps {
  e: {
    values: {
      surname: string;
      forename: string;
      email: string;
      password: string;
    };
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSubmit: () => void;
  };
}

const Form: React.FC<FormProps> = ({ e }) => {
  const { values, handleChange, handleSubmit } = e; 

  return (
    <form className="space-y-8 ng-untouched ng-pristine ng-valid my-6">
      <div className="space-y-4">
        <div className="space-y-2">
          <label htmlFor="surname" className="block text-sm">Sur Name</label>
          <input 
            value={values.surname} 
            onChange={handleChange} 
            type="text" 
            name="surname" 
            id="surname" 
            placeholder="surname" 
            className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" 
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="forename" className="block text-sm">Fore Name</label>
          <input 
            value={values.forename} 
            onChange={handleChange} 
            type="text" 
            name="forename" 
            id="forename" 
            placeholder="forename" 
            className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" 
          />
        </div>
        <div className="space-y-2">
          <label htmlFor="email" className="block text-sm">Email address</label>
          <input 
            value={values.email} 
            onChange={handleChange} 
            type="email" 
            name="email" 
            id="email" 
            placeholder="leroy@jenkins.com" 
            className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" 
          />
        </div>
        <div className="space-y-2">
          <div className="flex justify-between">
            <label htmlFor="password" className="text-sm">Password</label>
          </div>
          <input 
            value={values.password} 
            onChange={handleChange} 
            type="password" 
            name="password" 
            id="password" 
            placeholder="*****" 
            className="w-full px-3 py-2 border rounded-md border-brandbg2 bg-brandbg1 text-brandtext1 focus:border-brandbg2" 
          />
        </div>
      </div>

      <Button onClick={handleSubmit} style={{width: "100%",padding: "12px"}}>
        Sign Up
      </Button>
    </form>
  );
}

export default Form;
