import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAddNodeModalOpen } from "../../store/actions/globalStates";
import RecurringForm from '../RecurringForm';
import { RootState } from '../../store/store';
import { createPlanningDuration } from '../../store/actions';
import { InputField, TextArea } from '../FormFields';

interface FormProps {
  e: {
    values: {
      role?: string;
      objective: string;
      result: string;
      body: string;
      deadline: string;
      legtime: string;
      leadtime: string;
      taskOrder: number;
      estimatedTaskDuration: number;
      color: string;
      isImmediateTask: boolean;
      isRecurringTask: boolean;
    };
    handleChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    handleSubmit: (event: any) => void;
  };
  timingData: any;
  setTimingData: React.Dispatch<React.SetStateAction<any>>;
  startTime: Date;
}

const Form: React.FC<FormProps> = ({ e, timingData, setTimingData,startTime }) => {
  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  const submitBtnRef = useRef<HTMLButtonElement>(null);
  const depth = useSelector((state: RootState) => state.globalStates.depth);
  const parentId = useSelector((state: RootState) => state.globalStates.parentId);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
    if (event.code === "Enter") {
      submitBtnRef.current?.click();
    }
  };

  const handleSetObjective = () => {
    dispatch(setAddNodeModalOpen(false));
    navigate("/set-objective", { state: { isNewNode: true } });
  };

  return (
    <>
      <button
        onClick={handleSetObjective}
        className='text-sm bg-blue-600 text-white px-3 py-1 shadow-sm rounded-md'
      >
        Set Objective
      </button>
      <form className="w-full" tabIndex={0} onKeyDown={handleKeyDown}>
        {depth === 2 && (
          <InputField
            name="role"
            value={e.values.role ?? ''}
            handleChange={e.handleChange}
            label="Role"
            type="text"
          />
        )}
        <TextArea
          name="objective"
          isHeigLighted={true}
          value={e.values.objective}
          handleChange={e.handleChange}
          label="Objective"
        />

        <InputField
          name="result"
          value={e.values.result}
          handleChange={e.handleChange}
          label="Key Results"
          type="text"
        />
        <InputField
          name="body"
          value={e.values.body}
          handleChange={e.handleChange}
          label="Notes"
          type="text"
        />
        <InputField
          name="deadline"
          value={e.values.deadline}
          handleChange={e.handleChange}
          label="Deadline"
          type="datetime-local"
        />
        <InputField
          name="legtime"
          value={e.values.legtime}
          handleChange={e.handleChange}
          label="Lag Time"
          type="text"
        />
        <InputField
          name="leadtime"
          value={e.values.leadtime}
          handleChange={e.handleChange}
          label="Lead Time"
          type="text"
        />
        <InputField
          name="taskOrder"
          value={e.values.taskOrder}
          handleChange={e.handleChange}
          label="Task Order"
          type="number"
        />
        <InputField
          name="estimatedTaskDuration"
          value={e.values.estimatedTaskDuration}
          handleChange={e.handleChange}
          label="Est. Duration"
          type="number"
        />
        <InputField
          name="color"
          value={e.values.color}
          handleChange={e.handleChange}
          label="Color"
          type="color"
        />
        <h6 className='py-2 flex items-center gap-2'>
          ImmediateTask
          <input
            type="checkbox"
            checked={e.values.isImmediateTask}
            name='isImmediateTask'
            id='isImmediateTask'
            onChange={e.handleChange}
          />
        </h6>
        <h6 className='p-2 flex items-center justify-center gap-2'>
          Recuring Details
          <input
            type="checkbox"
            checked={e.values.isRecurringTask}
            name='isRecurringTask'
            id='isRecurringTask'
            onChange={e.handleChange}
          />
        </h6>
        {e.values.isRecurringTask && (
          <RecurringForm timingData={timingData} setTimingData={setTimingData} />
        )}
        <div className="flex items-center justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
          <button
            onClick={async () => {
              if(parentId){
                await createPlanningDuration({startTime,endTime: new Date(),node: parentId});
              }
              dispatch(setAddNodeModalOpen(false))
            }}
            data-modal-hide="defaultModal"
            type="button"
            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
          >
            Cancel
          </button>
          <button
            ref={submitBtnRef}
            onClick={e.handleSubmit}
            data-modal-hide="defaultModal"
            type="button"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Submit
          </button>
        </div>
      </form>
    </>
  );
};

export default Form;
