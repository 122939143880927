import React, { useEffect } from 'react';

interface FormFieldProps {
  name: string;
  label: string;
  type: string;
  value: string | number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isHeigLighted?: boolean;
}

export const InputField: React.FC<FormFieldProps> = ({ name, label, type, value, handleChange, isHeigLighted }) => {
  const myRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isHeigLighted && myRef.current) {
      myRef.current.focus();
    }
  }, [isHeigLighted]);

  return (
    <div className="md:flex md:items-center mb-6">
      <div className="md:w-1/3">
        <label
          className="block text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4"
          htmlFor={name}
        >
          {label}
        </label>
      </div>
      <div className="md:w-2/3">
        <input
          className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
          type={type}
          placeholder=""
          name={name}
          id={name}
          value={value}
          onChange={handleChange}
          autoFocus={isHeigLighted}
          ref={myRef}
        />
      </div>
    </div>
  );
}
