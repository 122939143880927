import React, { useEffect, useState } from 'react';
import { getHeightTopVal } from '../utils';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RootState } from '../../../store/store';

let fbs:any[] = [];

interface FocusBlockProps {
  top: number;
  current: Array<{ objective: string; block: number; color?: string }>;
  color?: string;
  coc: string;
}

interface FocusBlocksProps {
  todaysEvents: Array<{
    startTime: string;
    endTime: string;
    node: { color?: string; objective: string; };
  }>;
  currentDay: moment.Moment;
}

function FocusBlocks({ todaysEvents, currentDay }: FocusBlocksProps) {
  const tree = useSelector((state: RootState) => state.tree); // Adjust 'any' to your specific state shape
  const events = useSelector((state: RootState) => state.events); // Adjust 'any' to your specific state shape
  const todaysElapsedMinutes = moment().diff(moment().startOf("day"), 'minutes');
  const todayEvent:any = events?.find((ev:any) => moment(ev.startTime).day() === moment().day() && todaysElapsedMinutes >= moment(ev.startTime).diff(moment(ev.startTime).startOf("day"), "minutes") && todaysElapsedMinutes <= moment(ev.endTime).diff(moment(ev.endTime).startOf("day"), "minutes"));

  const [, setFocusBlocks] = useState<Array<{ objective: string; block: number; color?: string }>>([]);

  const te:any = events?.find((event:any) => moment().isBetween(moment(event.startTime), moment(event.endTime)));
  const filteredTodaysEvents = todaysEvents.filter((event:any) => event.node._id === te?.node._id);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(tree));

    function updateParentCompletionAndDuration(node: any) {
      if (!node.children || node.children.length === 0) {
        return {
          isCompleted: node.isCompleted,
          estimatedTaskDuration: node.estimatedTaskDuration,
        };
      } else {
        let allChildrenCompleted = true;
        let totalEstimatedDuration = 0;
        node.children.forEach((child: any) => {
          const childData = updateParentCompletionAndDuration(child);
          if (!childData.isCompleted) {
            allChildrenCompleted = false;
          }
          totalEstimatedDuration += childData.estimatedTaskDuration;
        });
        node.isCompleted = allChildrenCompleted;
        node.estimatedTaskDuration = totalEstimatedDuration;
        return {
          isCompleted: allChildrenCompleted,
          estimatedTaskDuration: totalEstimatedDuration,
        };
      }
    }

    updateParentCompletionAndDuration(data);

    let projects: Array<{ _id: string; parent: string; objective: string; estNoOfFocusBlocksToCompleteProjectOkr: number; }> = [];

    data.children.forEach((child: any) => {
      child.children.forEach((child1: any) => {
        if (child1._id === todayEvent?.node._id) {
          child1.children.forEach((d: any) => {
            const duration = d.estimatedTaskDuration;
            d.estNoOfFocusBlocksToCompleteProjectOkr = duration / 30;

            if (!d.isCompleted && !d.isDefered && !d.isDeleted && !isNaN(d.estNoOfFocusBlocksToCompleteProjectOkr)) {
              projects.push({
                _id: d._id,
                parent: child1._id,
                objective: d.objective,
                estNoOfFocusBlocksToCompleteProjectOkr: d.estNoOfFocusBlocksToCompleteProjectOkr,
              });
            }
          });
          return;
        }
      });
    });

    let sum = projects.reduce((a, v) => a + v.estNoOfFocusBlocksToCompleteProjectOkr, 0);
    let fbs: Array<any> = [];

    while (sum > 0) {
      const max = projects.reduce((a:any, b:any) => (a?.estNoOfFocusBlocksToCompleteProjectOkr > b?.estNoOfFocusBlocksToCompleteProjectOkr ? a : b), undefined);
      if (max.estNoOfFocusBlocksToCompleteProjectOkr >= 30) {
        max.estNoOfFocusBlocksToCompleteProjectOkr -= 30;
        fbs.push({ ...max, block: 1 });
      } else {
        const block = max.estNoOfFocusBlocksToCompleteProjectOkr / 30;
        fbs.push({ ...max, block });
        max.estNoOfFocusBlocksToCompleteProjectOkr = 0;
      }
      sum = projects.reduce((a, v) => a + v.estNoOfFocusBlocksToCompleteProjectOkr, 0);
    }

    setFocusBlocks(fbs);
  }, [tree, todayEvent]);

  return (
    <>
      {filteredTodaysEvents && filteredTodaysEvents.map((event, i) => {
        const today = currentDay.clone().set({
          hour: moment(event.startTime).hours(),
          minute: moment(event.startTime).minutes(),
        });
        const d = currentDay.startOf("day").diff(moment().startOf("day"), "days");
        const diff = d >= 0 ? today.diff(moment(), "minutes") : 0;

        const { top, height } = getHeightTopVal({ ...event, startTime: diff < 0 ? moment().toString() : event.startTime });
        const eventsTillNow = events?.filter((e:any) => moment(e.startTime).day() < currentDay.day() && moment(e.startTime).day() >= moment().day());
        const heightTillNow = eventsTillNow?.reduce((a:any, b:any) => a + getHeightTopVal(b).height, 0);
        const focusBlocksTillNow = heightTillNow / 30;

        let sumTillNow = 0;
        let indexTillNow = 0;

        fbs.forEach((el, i) => {
          if (sumTillNow < focusBlocksTillNow) {
            sumTillNow += el.block;
            indexTillNow = i;
          }
        });

        const remaining = sumTillNow - focusBlocksTillNow;
        if (remaining === 0) {
          fbs = fbs.filter((el, i) => i >= indexTillNow);
        } else {
          const left = [...fbs].filter((el, i) => i > indexTillNow - 1);
          if (left[0]) {
            left[0].block = remaining;
            fbs = left;
          }
        }

        let current: Array<any> = [];
        if (fbs.length > 0) {
          let sum = 0;
          let index = 0;
          const requiredFB = height / 30;
          fbs.forEach((el, i) => {
            if (sum < requiredFB) {
              sum += el.block;
              index = i;
            }
          });
          const remaining = sum - requiredFB;
          let left = [];
          if (remaining === 0) {
            left = fbs.filter((el, i) => i >= index);
            current = fbs.filter((el, i) => i <= index);
          } else {
            left = [...fbs].filter((el, i) => i > index - 1);
            current = [...fbs].filter((el, i) => i <= index);
            left[0].block = remaining;
          }
          fbs = left;

          const st = current.filter((el, i) => i < current.length - 1).reduce((a, b) => a + b.block, 0);
          const valueForLastFB = requiredFB - st;
          current[current.length - 1].block = valueForLastFB;
        }

        if (current.length === 0) {
          return null;
        }

        return <FocusBlock key={i} color={event.node?.color} coc={event.node?.objective} top={top} current={current} />;
      })}
    </>
  );
}

export default FocusBlocks;

function FocusBlock({ top, current, color, coc }: FocusBlockProps) {
  return (
    <div title={coc} style={{ top, position: "absolute", borderLeft: `4px solid ${color}` }} className="bg-transparent w-full overflow-auto">
      {current.map((val, i) => (
        <div title={val.objective} className='text-[10px] px-2 bg-red-400' key={i} style={{ height: `${val.block * 30}px` }}>
          {val.objective}
        </div>
      ))}
    </div>
  );
}
