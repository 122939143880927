import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../../store/actions/user";
import { Main } from "../../layout";
import { Formik, FormikHelpers } from "formik";
import Form from './Form';
import { RootState } from '../../store/store';

interface SignUpValues {
  surname: string;
  forename: string;
  email: string;
  password: string;
}

const initialValuesObj: SignUpValues = { surname: "", forename: "", email: "", password: "" };

const SignUp: React.FC = () => {
  const dispatch:any = useDispatch();
  const loggedInUser = useSelector((state: RootState) => state.loggedInUser); // Specify your state type here
  const navigator = useNavigate();

  const handleSubmit = (values: SignUpValues, { setSubmitting }: FormikHelpers<SignUpValues>) => {
    const temp = {
      ...values,
      name: `${values.forename} ${values.surname}`,
    };
    dispatch(createUser(temp));
    setSubmitting(false); // Optionally reset form submission state
  };

  useEffect(() => {
    if (loggedInUser) {
      navigator("/");
    }
  }, [loggedInUser, navigator]);

  return (
    <Main>
      <div className="h-full flex justify-center items-center">
        <div className="w-full max-w-md mx-auto my-10 p-4 rounded-md shadow sm:p-8 bg-gray-100 text-gray-900">
          <h2 className="mb-3 text-3xl font-semibold text-center">Create Account</h2>
          <p className="text-sm text-center text-gray-400">Already have an account?
            <Link to="/login" className='focus:underline hover:underline'>
              Login here
            </Link>
          </p>
          <Formik
            initialValues={initialValuesObj}
            validate={() => {}}
            onSubmit={handleSubmit}
          >
            {(e) => (
              <Form e={e} />
            )}
          </Formik>
        </div>
      </div>
    </Main>
  );
}

export default SignUp;
