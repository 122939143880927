import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { createPlanningDuration } from '../store/actions';

export function useStorePlanning() {
    const renderCount = useRef(0);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const navigatePointerNodeId = useSelector((state: RootState) => state.globalStates.navigatePointerNodeId);


    useEffect(() => {
        renderCount.current += 1;
        let timer:any = undefined;
  
        if(renderCount.current > 1){
          if(renderCount.current === 2){
            setStartTime(new Date());
          }
          
          timer = setTimeout(async () => {
            if(startTime !== null){
              const res = await createPlanningDuration({startTime,endTime:new Date()});
              if(!res?.success){
                console.log(res?.message);
              }
              renderCount.current = 1;
            }
          }, 1000*30);
        }
        
        return () => {
          clearTimeout(timer);  
        };
    }, [navigatePointerNodeId,startTime]);

    return null;
}

