import React from 'react';
import { useDispatch } from "react-redux";
import {  useLocation, useNavigate } from 'react-router-dom';
import objToArr from "./objToArr";
// import { updateNode } from '../../store/actions';
import { setAddNodeModalOpen, setDeadline, setObjectiveArr, setUpdateNodeModalOpen } from '../../store/actions/globalStates';
import { formatGoal } from "../../utils";
import { AppDispatch } from '../../store/store';
// import { RootState } from '../../store/store';

interface Goal {
    [_: string]: string;
}



function SecondPage({ setVisibility, goal }: { setVisibility: React.Dispatch<React.SetStateAction<{ firstPage: boolean; secondPage: boolean; }>>, goal: Goal }) {
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();
    const navigator = useNavigate();
    // const updateNodeBtnClickTime = useSelector((state: RootState) => state.globalStates.updateNodeBtnClickTime);


    const handleContinue = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log(location);
        const deadline = goal["_6"];
        if (!location.state.isNewNode) {
            // dispatch(updateNode({
            //     _id: location.state._id,
            //     deadline,
            //     objective: objToArr(goal).join(" "),
            //     objectiveArr: objToArr(goal),
            //     startTimeInMs: updateNodeBtnClickTime,
            //     endTimeInMs: new Date().getTime()
            // }));
            dispatch(setObjectiveArr(objToArr(goal)));
            dispatch(setDeadline(deadline));
            dispatch(setUpdateNodeModalOpen(true));
            navigator('/');
        } 
        else {
            navigator('/');
            dispatch(setAddNodeModalOpen(true));
            dispatch(setObjectiveArr(objToArr(goal)));
            dispatch(setDeadline(deadline));
        }
    };

    const handleBack = () => {
        setVisibility({ firstPage: true, secondPage: false });
    };

    return (
        <>
            <h3 className='pt-10 text-center font-bold mt-10'>My objective</h3>
            <p className='bg-white rounded-lg p-4 text-center mt-3 max-w-[500px] text-[20px] font-bold mx-auto'>
                {formatGoal(objToArr(goal))}
            </p>
            <div className="flex justify-center mt-5 gap-10">
                <button onClick={handleBack} className='rounded-md px-4 py-2 bg-blue-600 text-white font-bold'>Back</button>
                <button onClick={handleContinue} className='rounded-md px-4 py-2 bg-blue-600 text-white font-bold'>Next</button>
            </div>
        </>
    );
}

export default SecondPage;
