import React, { useEffect, useState, useMemo } from 'react';
import DayView from './DayView';
import WeekView from './WeekView';
import Header from './Header';
import { Button, Modal } from "../../../components";
import { createEvent, deleteEvent, updateEvent } from "../../../store/actions/event";
import { useDispatch } from "react-redux";
import moment from 'moment';
import { getPlanningDurationsOfLoggedInUser } from '../../../store/actions';
import { getExploitationDurationsOfLoggedInUser } from '../../../store/actions/exploitationDuration';

interface Task {
  _id: string;
  objective: string;
  taskExploitationDuration?: { startTime: string; endTime: string }[];
  taskPlanningDuration?: { startTimeInMs: string; endTimeInMs: string }[];
  children?: Task[];
}



interface CalendarVisibility {
  exploitationDurations: boolean;
  planningDurations: boolean;
  scheduleDurations: boolean;
  focusBlocks: boolean;
}

interface Event {
  _id: string;
  startTime: string;
  endTime: string;
  node?: { _id: string; objective: string };
}

const today = moment();

export default function Index({ tasks }: { tasks: Task[] | null,date?: any}) {
  const [weekAhead, setWeekAhead] = useState<number>(0);
  const [dayAhead, setDayAhead] = useState<number>(0);
  
  const startOfWeek = useMemo(() => {
    if (weekAhead === 0) {
      const temp = today.clone().startOf("week");
      return temp.clone().add(1, "day");
    }
    const temp = today.clone().add(weekAhead, "week").startOf("week");
    return temp.clone().add(1, "day");
  }, [weekAhead]);
  
  const currentDay = useMemo(() => today.clone().add(dayAhead, "days"), [dayAhead]);
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState<boolean>(false);
  const [currentView, setCurrentView] = useState<string>("Day view");
  const [exploitationDurations, setExploitationDurations] = useState<any[]>([]);
  const [planningDurations, setPlanningDurations] = useState<any[]>([]);
  const [calenderElVisibility, setCalenderElVisibility] = useState<CalendarVisibility>({
    exploitationDurations: true,
    planningDurations: true,
    scheduleDurations: true,
    focusBlocks: false,
  });
  const [eventToUpdate, setEventToUpdate] = useState<Event | null>(null);

 
  useEffect(() => {
    (async () => {
      const data = {startDate: new Date(),endDate: new Date()}
      if(currentView === "Day view"){
        data.startDate = currentDay.startOf("day").toDate();
        data.endDate = currentDay.endOf("day").toDate();
      }
      else {
        data.startDate = startOfWeek.toDate();
        data.endDate = startOfWeek.endOf("week").toDate();
      }
      const res = await getPlanningDurationsOfLoggedInUser({startDate: data.startDate,endDate: data.endDate});
      const res1 = await getExploitationDurationsOfLoggedInUser({startDate: data.startDate,endDate: data.endDate});

      // console.log(res1);
      

      if(res?.success){
        const planningDurations = res.planningDurations.map(planningDuration => {return {startTime: planningDuration.startTime, endTime: planningDuration.endTime, planningDuration: true, objective: planningDuration.node?.objective || "Global Planning"}});
        setPlanningDurations(prev => {
          return [...prev,...planningDurations];
        })
      }
      if(res1?.success){
        const exploitationDurations = res1.exploitationDurations.map(exploitationDuration => {
          const color:any =  exploitationDuration.nodesTillLifeDivisions[1];
          return {
            startTime: exploitationDuration.startTime, 
            endTime: exploitationDuration.endTime, 
            exploitationDuration: true, 
            objective: exploitationDuration.objectivesTillLifeDivisions[1],
            color: color?.color || "green"
          }});
        setExploitationDurations(prev => {
          return [...prev,...exploitationDurations];
        })
      }
    })()
  }, [currentDay,startOfWeek,currentView]);

  return (
    <div className="flex h-full flex-col">
      <Header 
        setWeekAhead={setWeekAhead} 
        weekAhead={weekAhead? true:false} 
        setIsAddEventModalOpen={setIsAddEventModalOpen} 
        currentView={currentView} 
        setcurrentView={setCurrentView}
        startOfWeek={startOfWeek}
        setDayAhead={setDayAhead}
        currentDay={currentDay}
        dayAhead={dayAhead}
        calenderElVisivility={calenderElVisibility}
        setCalenderElVisivility={setCalenderElVisibility}
        setEventToUpdate={setEventToUpdate}
      />
      <div className="flex h-full flex-auto bg-white">
        {currentView === "Day view" && (
          <DayView 
            currentDay={currentDay} 
            exploitationDurations={exploitationDurations}
            planningDurations={planningDurations}
            calenderElVisivility={calenderElVisibility}
            setEventToUpdate={setEventToUpdate}
            setIsAddEventModalOpen={setIsAddEventModalOpen}
          />
        )}
        {currentView === "Week view" && (
          <WeekView 
            startOfWeek={startOfWeek} 
            exploitationDurations={exploitationDurations}
            planningDurations={planningDurations}
            weekAhead={weekAhead? true:false}
            calenderElVisivility={calenderElVisibility}
            setEventToUpdate={setEventToUpdate}
            setIsAddEventModalOpen={setIsAddEventModalOpen}
          />
        )}
      </div>
      {/* Add event modal */}
      <Form 
        tasks={tasks} 
        setEventToUpdate={setEventToUpdate} 
        eventToUpdate={eventToUpdate} 
        isAddEventModalOpen={isAddEventModalOpen} 
        setIsAddEventModalOpen={setIsAddEventModalOpen}
      />
    </div>
  );
}

interface FormProps {
  tasks: Task[] | null;
  isAddEventModalOpen: boolean;
  setIsAddEventModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  eventToUpdate: Event | null;
  setEventToUpdate: React.Dispatch<React.SetStateAction<Event | null>>;
}

function Form({ tasks, isAddEventModalOpen, setIsAddEventModalOpen, eventToUpdate, setEventToUpdate }: FormProps) {
  const dispatch:any = useDispatch();
  const [initialValues, setInitialValues] = useState<{ startTime: string; endTime: string; node: string }>({ startTime: "", endTime: "", node: "" });

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    if (e.target.name === "personal" || e.target.name === "professional") {
      if (eventToUpdate) {
        return;
      }
    }

    setInitialValues(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = () => {
    if (eventToUpdate) {
      dispatch(updateEvent(eventToUpdate._id, { endTime: initialValues.endTime, startTime: initialValues.startTime }));
      setIsAddEventModalOpen(false);
      setEventToUpdate(null);
      return;
    }
    const event = { ...initialValues };
    event.startTime = new Date(event.startTime).toISOString();
    event.endTime = new Date(event.endTime).toISOString();
    dispatch(createEvent({ ...event }));
    setIsAddEventModalOpen(false);
  };

  const handleDelete = () => {
    if (eventToUpdate) {
      dispatch(deleteEvent(eventToUpdate._id));
      setIsAddEventModalOpen(false);
      setEventToUpdate(null);
    }
  };

  useEffect(() => {
    const allValues = { startTime: "", endTime: "", node: "" };
    if (eventToUpdate) {
      const startTime = moment(eventToUpdate.startTime).format('YYYY-MM-DDTHH:mm');
      const endTime = moment(eventToUpdate.endTime).format('YYYY-MM-DDTHH:mm');
      setInitialValues(prev => ({
        ...prev,
        endTime,
        startTime
      }));
    } else {
      setInitialValues(allValues);
    }
  }, [eventToUpdate]);

  return (
    <>
      <Modal saveBtnText={eventToUpdate ? "Update" : "Save"} onSubmit={handleSubmit} isOpen={isAddEventModalOpen} setIsOpen={setIsAddEventModalOpen}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="startTime">
            Start Time
          </label>
          <input value={initialValues.startTime} onChange={handleChange} className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="startTime" name="startTime" type="datetime-local" />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="endTime">
            End Time
          </label>
          <input value={initialValues.endTime} onChange={handleChange} className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="endTime" name="endTime" type="datetime-local" />
        </div>
        {tasks && tasks.map((task, idx) => (
          <div key={idx} className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`node-${idx}`}>
              {task.objective}
            </label>
            <select name="node" id={`node-${idx}`} value={eventToUpdate?.node?._id || initialValues.node} onChange={handleChange} className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              <option value="">--</option>
              {task.children?.map(t => <option key={t._id} value={t._id}>{t.objective}</option>)}
            </select>
          </div>
        ))}
        {eventToUpdate && (
          <Button onClick={handleDelete} style={{backgroundColor: "#ff0000",width: "100%",padding: '10px'}}>Delete</Button>
        )}
      </Modal>
    </>
  );
}
