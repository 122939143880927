import React, { useState, useEffect } from 'react';


interface MultiStepProps {
  steps: number;
  completedSteps: number;
}

const MultiStep: React.FC<MultiStepProps> = ({ steps, completedSteps }) => {
  const [showGlow, setShowGlow] = useState<boolean>(false);
    
  useEffect(() => {
    setShowGlow(true);
    setTimeout(() => {
      setShowGlow(false);
    }, 500);
  }, [completedSteps]);

  return (
    <div>
      <ol className="flex items-center w-full mb-4 pl-0 sm:mb-5">
        {
          new Array(steps).fill(1).map((_, i) => {
            const item = i + 1;
            
            if (item === steps) {
              if (completedSteps === steps) {
                return (
                  <li key={i} className="flex text-white items-center">
                    <div className={`transition-shadow ${showGlow && item === completedSteps ? "shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]" : ""} shadow-blue-400 flex items-center justify-center w-6 h-6 bg-blue-700 rounded-full lg:h-8 lg:w-8 dark:bg-blue-800 shrink-0`}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <polygon fill="#2dea55" points="24.969,33.926 12.525,20.352 15.475,17.648 25.031,28.074 45.555,6.618 48.445,9.382"></polygon><path fill="#ffd217" d="M43.853,11.878l-3.031,2.652C42.82,17.536,44,21.128,44,25c0,10.477-8.523,19-19,19S6,35.477,6,25 S14.523,6,25,6c4.39,0,8.426,1.511,11.647,4.021l2.785-2.912C35.483,3.917,30.462,2,25,2C12.317,2,2,12.318,2,25s10.317,23,23,23 s23-10.318,23-23C48,20.121,46.456,15.604,43.853,11.878z"></path><path fill="#0cbc35" d="M42.367,9.951l-2.779,2.905c0.875,1.049,1.627,2.199,2.263,3.42l2.9-3.032 C44.052,12.076,43.254,10.974,42.367,9.951z"></path>
                        </svg>
                    </div>
                  </li>
                );
              } else {
                return (
                  <li key={i} className="flex items-center">
                    <div className="flex items-center justify-center w-6 h-6 bg-blue-700 rounded-full lg:h-8 lg:w-8 dark:bg-gray-700 shrink-0" />
                  </li>
                );
              }
            }

            if (item <= completedSteps) {
              return (
                <li key={i} className="flex w-full items-center text-white dark:text-green-500 after:content-[''] after:w-full after:h-1 after:border-b after:border-blue-400 after:border-4 after:inline-block dark:after:border-green-800">
                  <div className={`transition-shadow ${showGlow && item === completedSteps ? "shadow-[0_0_10px_10px_rgba(0,0,0,0.3)]" : ""} shadow-blue-700 flex items-center justify-center w-6 h-6 bg-blue-700 rounded-full lg:h-8 lg:w-8 dark:bg-blue-800 shrink-0`}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="50" height="50" viewBox="0 0 50 50">
                            <polygon fill="#2dea55" points="24.969,33.926 12.525,20.352 15.475,17.648 25.031,28.074 45.555,6.618 48.445,9.382"></polygon><path fill="#ffd217" d="M43.853,11.878l-3.031,2.652C42.82,17.536,44,21.128,44,25c0,10.477-8.523,19-19,19S6,35.477,6,25 S14.523,6,25,6c4.39,0,8.426,1.511,11.647,4.021l2.785-2.912C35.483,3.917,30.462,2,25,2C12.317,2,2,12.318,2,25s10.317,23,23,23 s23-10.318,23-23C48,20.121,46.456,15.604,43.853,11.878z"></path><path fill="#0cbc35" d="M42.367,9.951l-2.779,2.905c0.875,1.049,1.627,2.199,2.263,3.42l2.9-3.032 C44.052,12.076,43.254,10.974,42.367,9.951z"></path>
                        </svg>
                  </div>
                </li>
              );
            } else {
              return (
                <li key={i} className="flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-400 after:border-4 after:inline-block dark:after:border-gray-700">
                  <div className="flex items-center justify-center w-6 h-6 bg-blue-600 rounded-full lg:h-8 lg:w-8 dark:bg-gray-700 shrink-0" />
                </li>
              );
            }
          })
        }
      </ol>
    </div>
  );
};

export default MultiStep;
