import { DOMAIN_NAME } from "../../constants";
import { fetchRequest } from "../../utils";
import { setNavigatePointerNodeId } from "./index";

interface StateResponse {
    success: boolean;
    message?: string;
    state?: {
        tree: string;
        // Add other properties as needed
    };
}

interface UpdateOrCreateStateResponse {
    success: boolean;
    message?: string;
    // Add other properties as needed
}

export const getStateOfUser = () => async (dispatch: any) => {
    try {
        const res: StateResponse = await fetchRequest(`${DOMAIN_NAME}/states`, "GET", {});

        if (res.success && res.state) {
            dispatch(setNavigatePointerNodeId(res.state.tree));
        } else {
            // toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}

export const updateOrCreateState = async (treeId: string, captureParkId: string) => {
    try {
        const res: UpdateOrCreateStateResponse = await fetchRequest(`${DOMAIN_NAME}/states?treeId=${treeId}&&captureParkId=${captureParkId}`, "POST", {});

        if (res.success) {
            // Handle success case if needed
        } else {
            // toast.error(res.message);
        }
    } catch (error) {
        console.error(error);
    }
}
