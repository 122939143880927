import { 
    SET_CHALLENGES,
    ADD_CHALLENGE,
    REMOVE_CHALLENGE,
    UPDATE_CHALLENGE
} from "../../constants";


interface ChallengesAction {
    type: string;
    payload: any;
}

const challenges = (state: any[] = [], action: ChallengesAction): any[] => {
    switch (action.type) {
        case SET_CHALLENGES:
            return action.payload;

        case ADD_CHALLENGE:
            return [...state, action.payload];

        case UPDATE_CHALLENGE:
            return state.map(ch => ch._id === action.payload._id ? { ...ch, ...action.payload } : ch);

        case REMOVE_CHALLENGE:
            return state.filter(ch => ch._id !== action.payload.challengeId);

        default:
            return state;
    }
}

export { challenges };




