import React, { useState } from 'react';
import { Main } from "../../layout";
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import { useLocation } from 'react-router-dom';
import moment from 'moment';


function Index() {
    const location = useLocation();
    const [visibility, setVisibility] = useState<{ firstPage: boolean; secondPage: boolean }>({
        firstPage: true,
        secondPage: false
    });
    const [goal, setGoal] = useState<{ [_: string]: string }>(() => {
        const isSet = location?.state?.objectiveArr?.length >= 1;
        const temp: { [_: string]: string } = {};
        if (isSet) {
            const arr = location.state.objectiveArr;
            arr.forEach((el:any, i:number) => {
                let element = el;
                if (i === 5) {
                    element = moment(new Date(element)).format("YYYY-MM-DD");
                }
                temp[`_${i + 1}`] = element;
            });
        }
        return temp;
    });

    return (
        <Main>
            <div className="py-10 px-3">
                {visibility.firstPage && <FirstPage goal={goal} setGoal={setGoal} setVisibility={setVisibility} />}
                {visibility.secondPage && <SecondPage goal={goal} setVisibility={setVisibility} />}
            </div>
        </Main>
    );
}

export default Index;
