import React, { useState } from "react";
import Modal from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import {
   setAddNodeModalOpen,
   createNode, 
   pauseTask,
   setObjectiveArr,
   setDeadline,
   createPlanningDuration
} from "../../store/actions";

import {Formik} from "formik";
import Form from "./Form";
import { toast } from "react-toastify";
import {RootState} from "../../store/store";
import moment from "moment";


function Index() {
  const nodeObj:any = {
      role: "",
      objective: "",
      objectiveArr: [],
      result: "",
      body: "",
      deadline: "",
      legtime: "",
      leadtime: "",
      taskOrder: 0,
      estimatedTaskDuration: 1,
      color: ""
  }
  const dispatch:any = useDispatch();
  const isAddNodeModalOpen = useSelector((state: RootState) => state.globalStates.isAddNodeModalOpen);
  const parentId = useSelector((state: RootState) => state.globalStates.parentId);
  const createNodeBtnClickTime = useSelector((state: RootState) => state.globalStates.createNodeBtnClickTime);
  const objectiveArr = useSelector((state: RootState) => state.globalStates.objectiveArr);
  const deadline = useSelector((state: RootState) => state.globalStates.deadline);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  const tree = useSelector((state: RootState) => state.tree);
  const [timingData,setTimingData] = useState({
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    time: "",
    days: [],
    frequency: ""
  });
  const [startTime] = useState(new Date());
  
  if(deadline){
    nodeObj.deadline = deadline;
  }
  if(objectiveArr){
    nodeObj.objectiveArr = objectiveArr;
    nodeObj.objective = objectiveArr.join(" ");
  }

  const handleSubmit = async (values:any,e:any) => { 
    if(values.isRecurringTask && timingData.days.length === 0){
      toast.error("Recurring Tasks Should have at least one day selected");
      return;
    }

    if(!values.estimatedTaskDuration || values.estimatedTaskDuration === 0){
      toast.error("Estimated Task Duration can't be zero or Undefined");
      return;
    }

    let isProjectAndObjectiveNode = false;

    tree?.children.forEach(child => {
      child.children.forEach(chidl1 => {
        if(chidl1._id === parentId){
          isProjectAndObjectiveNode = true;
        }
      })
    });


    if(isProjectAndObjectiveNode && (!values.deadline || new Date(values.deadline) < new Date())){
      if(parentId === "668261e1c1c046a9f34c8472" || parentId === "668284ed97ec14f3d5628a0b"){
        values.deadline = moment().endOf('day').format("YYYY-MM-DDTHH:mm:ss");
      }
      else {
        toast.error("Deadline is required and must be set in the future.")
        return;
      }
    }
    
    function createNodeLocal() {
      dispatch(createNode({...values,parent: parentId,startTimeInMs:createNodeBtnClickTime,endTimeInMs: new Date().getTime(),isRecurringTask: timingData.frequency? true:false,recurring: {...timingData}}));
      e.resetForm();
      setTimingData({
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        time: "",
        days: [],
        frequency: ""
      });
      dispatch(setAddNodeModalOpen(false));
      dispatch(setDeadline(null));
      dispatch(setObjectiveArr(null));
    }

    // if you are adding the node the the running node 
    if(currentTask && currentTask?._id === parentId && currentTask?.startTime){
      const funcs = {
          preRun: () => {},
          runIfSuccess: createNodeLocal,
          runIfFails: () => {}
      }

			dispatch(pauseTask(currentTask._id,"",false,funcs));
      console.log("Task has been paused here");
    }
    else {
      createNodeLocal();
    }
  };

  const onEscape = async () => {
    dispatch(setObjectiveArr(null));
    dispatch(setDeadline(null));
    dispatch(setAddNodeModalOpen(false));
    if(parentId){
      await createPlanningDuration({startTime,endTime: new Date(),node: parentId});
    }
  }

  return (
    <Modal
      saveBtnText="Create"
      onSubmit={()=>{}}
      isOpen={isAddNodeModalOpen}
      setIsOpen={(bool)=>dispatch(setAddNodeModalOpen(bool))}
      hideBtns={true}
      onEscape={onEscape}
    >
      <Formik
      initialValues={{...nodeObj,isRecurringTask: false}}
      validate={()=>{}}
      onSubmit={handleSubmit}
      >
        {(e:any)=>(
          <Form e={e} startTime={startTime} timingData={timingData} setTimingData={setTimingData}/>
        )}
      </Formik>
    </Modal>
  );
}

export default Index;





