import React, { useState, useEffect } from "react";
import Modal from "../Modal/Index";
import { useSelector, useDispatch } from "react-redux";
import { 
  updateNode,
  setCurrentTask, 
  setUpdateNodeModalOpen,
  createPlanningDuration,
  getTaskById,
  setDeadline,
  setObjectiveArr
} from "../../store/actions";
import { Formik } from "formik";
import Form from "./Form";
import moment from "moment";
import { toast } from "react-toastify";
import { RootState } from "../../store/store";

interface MyNode {
  _id: string;
  deadline?: string;
  objective?: string;
  estimatedTaskDuration?: number;
  recurring?: {
    frequency?: string;
    duration?: any; // Replace with the actual type if known
    startTime?: string;
    endTime?: string;
    time?: string;
    hours?: number;
    minutes?: number;
    days?: string[]; // or any appropriate type
  };
}



const Index: React.FC = () => {
  const dispatch:any = useDispatch();
  const isUpdateNodeModalOpen = useSelector((state: RootState) => state.globalStates.isUpdateNodeModalOpen);
  const updateNodeBtnClickTime:any = useSelector((state: RootState) => state.globalStates.updateNodeBtnClickTime);
  const currentTask = useSelector((state: RootState) => state.globalStates.currentTask);
  // const myNode = useSelector((state: RootState) => state.globalStates.node);
  const nodeIdToBeEdited = useSelector((state: RootState) => state.globalStates.nodeIdToBeEdited);
  const tree = useSelector((state: RootState) => state.tree);
  const [nodeToBeEdited, setNodeToBeEdited] = useState<any>(null);
  const [timingData, setTimingData] = useState<{ [key: string]: any }>({});
  const deadlineG = useSelector((state: RootState) => state.globalStates.deadline); 
  const deadline = nodeToBeEdited?.deadline ? moment(nodeToBeEdited.deadline).format("YYYY-MM-DDTHH:mm") : "";
  const objectiveArr = useSelector((state: RootState) => state.globalStates.objectiveArr);
  

  const handleSubmit = (values: MyNode, e: { resetForm: () => void }) => {    
    if (!values.estimatedTaskDuration || values.estimatedTaskDuration === 0) {
      toast.error("Estimated Task Duration can't be zero or Undefined");
      return;
    }

    let isProjectAndObjectiveNode = false;

    tree?.children.forEach(child => {
      child.children.forEach(chidl1 => {
        chidl1.children.forEach(chidl2 => {
          if(chidl2._id === values._id){
            isProjectAndObjectiveNode = true;
          }
        })
      })
    });

    if(isProjectAndObjectiveNode && (!values.deadline || new Date(values.deadline) < new Date())){
      toast.error("Deadline is required and must be set in the future.")
      return;
    }
    
    dispatch(updateNode({ ...values, startTimeInMs: updateNodeBtnClickTime, endTimeInMs: new Date().getTime(), recurring: timingData }));
    
    if (currentTask?._id === values._id) {
      dispatch(setCurrentTask({ 
        ...values, 
        startTimeInMs: updateNodeBtnClickTime, 
        endTimeInMs: new Date().getTime(), 
        recurring: timingData,
        nodeProductivityValue: currentTask.nodeProductivityValue
      }));
    }
    
    e.resetForm();
    dispatch(setUpdateNodeModalOpen(false));
    dispatch(setDeadline(null));
    dispatch(setObjectiveArr(null));
  };

  const onEscape = async () => {
    await createPlanningDuration({startTime: updateNodeBtnClickTime,endTime: new Date(),node: nodeToBeEdited?._id});
    dispatch(setUpdateNodeModalOpen(false));
  }

  useEffect(() => {
    setTimingData(() => {
      const recurring = nodeToBeEdited?.recurring;
      if (!recurring?.frequency) {
        return {
          startTime: "",
          endTime: "",
          time: "",
          days: [],
          frequency: ""
        };
      }
      return {
        startTime: !recurring.duration ? recurring.startTime : recurring.duration.start || "",
        endTime: !recurring.duration ? recurring.endTime : recurring.duration.end || "",
        time: !recurring.hours ? recurring.time : `${recurring.hours < 10 ? `0${recurring.hours}` : recurring.hours}:${(recurring.minutes && recurring.minutes < 10) ? `0${recurring.minutes}` : recurring.minutes}`,
        days: recurring.days || [],
        frequency: recurring.frequency || ""
      };
    });
  }, [nodeToBeEdited]);


  useEffect(() => {
    (async () => {
      if(nodeIdToBeEdited){
        // console.log(nodeIdToBeEdited);
        const res = await getTaskById(nodeIdToBeEdited);
        if(res && res.success){
          const node = res.node;
          if(deadlineG){
            node.deadline = deadlineG;
          }
          if(objectiveArr){
            node.objectiveArr = objectiveArr;
            node.objective = objectiveArr.join(" ");
          }
          setNodeToBeEdited(node)
        }
      }
    })()
  }, [nodeIdToBeEdited,deadlineG,objectiveArr]);


  return (
    <Modal
      saveBtnText="Create"
      onSubmit={handleSubmit}
      isOpen={isUpdateNodeModalOpen}
      setIsOpen={(bool) => { dispatch(setUpdateNodeModalOpen(bool)); }}
      hideBtns={true}
      onEscape={onEscape}
    >
      {nodeToBeEdited && (
        <Formik
          initialValues={{ ...nodeToBeEdited, deadline, objective: nodeToBeEdited?.objective }}
          onSubmit={handleSubmit}
          validate={() => { }}
        >
          {(e) => (
            <Form e={e} nodeToBeEdited={nodeToBeEdited} timingData={timingData} setTimingData={setTimingData} />
          )}
        </Formik>
      )}
    </Modal>
  );
}

export default Index;
