import {
    RESET_TREE,
    SET_TREE,
    ADD_NODE,
    UPDATE_NODE,
    DELETE_NODE,
    COPY_PASTE_NODES,
    CUT_PASTE_NODES,
    TOGGLE_COMPLETED_NODES,
    TOGGLE_DELETED_NODES,
    UNCOMPLETE_COMPLETED_NODES,
    UN_DEFERED_DESCENDANTS,
    UPDATE_MULTIPLE_NODES,
    UN_COMPLETE_MULTIPLE_NODES,
    RANK_NODES
} from "../../constants";

interface Node {
    _id: string;
    parent?: string;
    children: Node[];
    completedNodes?: Node[];
    deletedNodes?: Node[];
    [key: string]: any; // Additional properties
}

interface Action {
    type: string;
    payload: any; // Define this type based on the payload of each action
}

const tree = (state: Node | null = null, action: Action): Node | null => {
    switch (action.type) {
        case SET_TREE:
            return action.payload;

        case ADD_NODE:
            return addNode(state, action.payload.newNode);

        case UPDATE_NODE:
            return updateNode(state, action.payload);

        case UPDATE_MULTIPLE_NODES:
            return updateNodeMultipleNodes(state, action.payload);

        case DELETE_NODE:
            return deleteNode(state, action.payload.nodeId, action.payload.parentNodeId);

        case COPY_PASTE_NODES:
            const { destinationId, newSourceNode } = action.payload;
            return copyPasteNodes(state, destinationId, newSourceNode);

        case CUT_PASTE_NODES:
            return cutPasteNodes(state, action.payload.sourceId, action.payload.sourceParentId, action.payload.destinationId);

        case TOGGLE_COMPLETED_NODES:
            return toggleCompletedNodes(state, action.payload);

        case TOGGLE_DELETED_NODES:
            return toggleDeletedNodes(state, action.payload);

        case UNCOMPLETE_COMPLETED_NODES:
            return unCompleteCompletedNodes(state, action.payload);

        case UN_DEFERED_DESCENDANTS:
            return unDeferDescendants(state, action.payload);

        case UN_COMPLETE_MULTIPLE_NODES:
            return unCompleteMultipleNodes(state, action.payload);

        case RANK_NODES:
            return rankNodes(state, action.payload);

        case RESET_TREE:
            return null;

        default:
            return state;
    }
}

export default tree;

function addNode(tree: any | null, newNode: Node): Node {
    if (!tree) {
        return newNode;
    }

    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (node._id === newNode.parent) {
            node.children.push(newNode);
        }
    });

    return root;
}

function updateNode(tree: any, newNode: Node): Node {
    const excludedKeys = ["children"];
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (node._id === newNode._id) {
            for (const key in newNode) {
                if (excludedKeys.indexOf(key) === -1) {
                    if ([node[key] !== newNode[key]]) {
                        node[key] = newNode[key];
                    }
                }
            }
        }
    });

    return root;
}

function rankNodes(tree: any, nodesToRank: Node): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (node._id === nodesToRank[0].parent) {
            const children:any = [];
            nodesToRank.forEach((child:any) => {
                const ch = node.children.find(c => c._id === child._id);
                children.push(ch);
            });
            node.children = children;
        }
    });

    return root;
}

function updateNodeMultipleNodes(tree: any, newNodes: Node[]): Node {
    const excludedKeys = ["children"];
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        const index = newNodes.findIndex(n => n._id === node._id);
        if (index !== -1) {
            for (const key in newNodes[index]) {
                if (excludedKeys.indexOf(key) === -1) {
                    if ([node[key] !== newNodes[index][key]]) {
                        node[key] = newNodes[index][key];
                    }
                }
            }
        }
    });

    return root;
}

function unCompleteMultipleNodes(tree: any, idsToBeUpdated: any[]): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        const index = idsToBeUpdated.findIndex(n => n === node._id);
        if (index !== -1) {
            node.isCompleted = false;
        }
    });

    return root;
}

function deleteNode(tree: any, nodeId: string, parentNodeId: string): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (parentNodeId && node._id === parentNodeId) {
            node.children = node.children.filter(n => n._id !== nodeId);
        }
    });

    return root;
}

function copyPasteNodes(tree: any, destinationId: string, newSourceNode: Node): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (node._id === destinationId) {
            node.children.push(newSourceNode);
        }
    });

    return root;
}

function cutPasteNodes(tree: any, sourceId: string, sourceParentId: string, destinationId: string): Node {
    const root = JSON.parse(JSON.stringify(tree));
    let cutNode: Node | undefined;

    visit(root, node => {
        if (node._id === sourceParentId) {
            cutNode = node.children.find(n => n._id === sourceId);
            node.children = node.children.filter(n => n._id !== sourceId);
        }
    });

    visit(root, node => {
        if (node._id === destinationId && cutNode) {
            node.children.push(cutNode);
        }
    });

    return root;
}

function toggleCompletedNodes(tree: any, isCompletedNodesVisible: boolean): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (isCompletedNodesVisible) {
            if (node.completedNodes?.length) {
                node.children = [...node.children, ...node.completedNodes];
            }
        } else {
            node.completedNodes = node.children.filter(n => n.isCompleted);
            node.children = node.children.filter(n => !n.isCompleted);
        }
    });

    return root;
}

function toggleDeletedNodes(tree: any, isDeletedNodesVisible: boolean): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (isDeletedNodesVisible) {
            if (node.deletedNodes?.length) {
                node.children = [...node.children, ...node.deletedNodes];
            }
        } else {
            node.deletedNodes = node.children.filter(n => n.deletedNodes);
            node.children = node.children.filter(n => !n.deletedNodes);
        }
    });

    return root;
}

function unCompleteCompletedNodes(tree: any, arrOfIds: string[]): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (arrOfIds.indexOf(node._id) !== -1) {
            node.isCompleted = false;
        }
    });

    return root;
}

function unDeferDescendants(tree: any, arrOfIds: string[]): Node {
    const root = JSON.parse(JSON.stringify(tree));

    visit(root, node => {
        if (arrOfIds.indexOf(node._id) !== -1) {
            node.isDefered = false;
        }
    });

    return root;
}

function visit(node: any, callback: (node: Node) => void): void {
    callback(node);
    if (node.children) {
        node.children.forEach((child:any) => visit(child, callback));
    }
}
